/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProfileLink_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PostItem_post$ref: FragmentReference;
declare export opaque type PostItem_post$fragmentType: PostItem_post$ref;
export type PostItem_post = {|
  +id: string,
  +url: string,
  +title: string,
  +publishedAt: ?any,
  +commenting: ?{|
    +count: ?number
  |},
  +voting: ?{|
    +countUps: ?number,
    +countDowns: ?number,
  |},
  +tags: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +title: string,
        +slug: string,
      |}
    |}>
  |},
  +revisionCreated: ?{|
    +author: ?{|
      +$fragmentRefs: ProfileLink_user$ref
    |}
  |},
  +$refType: PostItem_post$ref,
|};
export type PostItem_post$data = PostItem_post;
export type PostItem_post$key = {
  +$data?: PostItem_post$data,
  +$fragmentRefs: PostItem_post$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PostItem_post",
  "type": "Post",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "publishedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "commenting",
      "storageKey": null,
      "args": null,
      "concreteType": "Commenting",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "voting",
      "storageKey": null,
      "args": null,
      "concreteType": "Voting",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "countUps",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "countDowns",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tags",
      "storageKey": "tags(first:50)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        }
      ],
      "concreteType": "TagConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TagEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Tag",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "slug",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "revisionCreated",
      "storageKey": null,
      "args": null,
      "concreteType": "Revision",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "author",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "ProfileLink_user",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d85a3f58f6345f5455d61bb5e2bc365';
module.exports = node;
