/**
 * @flow
 * @relayHash a1f4d24350fc4bae6a48f900d53307fa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProfileChangeAvatarQueryVariables = {||};
export type ProfileChangeAvatarQueryResponse = {|
  +me: ?{|
    +id: string,
    +username: string,
    +firstName: string,
    +email: string,
    +avatar: ?{|
      +url: string
    |},
    +isAuthenticated: ?boolean,
    +myPerms: ?$ReadOnlyArray<?string>,
  |}
|};
export type ProfileChangeAvatarQuery = {|
  variables: ProfileChangeAvatarQueryVariables,
  response: ProfileChangeAvatarQueryResponse,
|};
*/


/*
query ProfileChangeAvatarQuery {
  me {
    id
    username
    firstName
    email
    avatar(width: 100, height: 100) {
      url
    }
    isAuthenticated
    myPerms
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "avatar",
        "storageKey": "avatar(height:100,width:100)",
        "args": [
          {
            "kind": "Literal",
            "name": "height",
            "value": 100
          },
          {
            "kind": "Literal",
            "name": "width",
            "value": 100
          }
        ],
        "concreteType": "File",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isAuthenticated",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "myPerms",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProfileChangeAvatarQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ProfileChangeAvatarQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ProfileChangeAvatarQuery",
    "id": null,
    "text": "query ProfileChangeAvatarQuery {\n  me {\n    id\n    username\n    firstName\n    email\n    avatar(width: 100, height: 100) {\n      url\n    }\n    isAuthenticated\n    myPerms\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b94d035e32da5bdc8714e36d134f2c6';
module.exports = node;
