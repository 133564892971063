/**
 * @flow
 * @relayHash 5d500ebfa338bb969af7f323eb14eaa4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HomePlantQueryVariables = {||};
export type HomePlantQueryResponse = {|
  +viewer: ?{|
    +id: string
  |},
  +ranks: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +edibilities: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
|};
export type HomePlantQuery = {|
  variables: HomePlantQueryVariables,
  response: HomePlantQueryResponse,
|};
*/


/*
query HomePlantQuery {
  viewer {
    id
  }
  ranks: __type(name: "Rank") {
    enumValues {
      name
      description
    }
  }
  edibilities: __type(name: "Edibility") {
    enumValues {
      name
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "enumValues",
    "storageKey": null,
    "args": null,
    "concreteType": "__EnumValue",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "viewer",
    "storageKey": null,
    "args": null,
    "concreteType": "Query",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": "ranks",
    "name": "__type",
    "storageKey": "__type(name:\"Rank\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Rank"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "edibilities",
    "name": "__type",
    "storageKey": "__type(name:\"Edibility\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Edibility"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HomePlantQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "HomePlantQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "HomePlantQuery",
    "id": null,
    "text": "query HomePlantQuery {\n  viewer {\n    id\n  }\n  ranks: __type(name: \"Rank\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  edibilities: __type(name: \"Edibility\") {\n    enumValues {\n      name\n      description\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd14a2dad809a861b8d5d06c3de4d55b0';
module.exports = node;
