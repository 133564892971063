/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlantLink_plant$ref: FragmentReference;
declare export opaque type PlantLink_plant$fragmentType: PlantLink_plant$ref;
export type PlantLink_plant = {|
  +id: string,
  +idInt: ?number,
  +title: string,
  +slug: ?string,
  +$refType: PlantLink_plant$ref,
|};
export type PlantLink_plant$data = PlantLink_plant;
export type PlantLink_plant$key = {
  +$data?: PlantLink_plant$data,
  +$fragmentRefs: PlantLink_plant$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PlantLink_plant",
  "type": "LifeNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "idInt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c3db1e270099eee88185d868037fe7ad';
module.exports = node;
