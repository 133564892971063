/**
 * @flow
 * @relayHash 6fedd302e747d0675c687837bb8d536b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommentsItem_comment$ref = any;
export type CommentsItemQueryVariables = {|
  id: string,
  isRepliesExpanded: boolean,
|};
export type CommentsItemQueryResponse = {|
  +comment: ?{|
    +id: string,
    +$fragmentRefs: CommentsItem_comment$ref,
  |}
|};
export type CommentsItemQuery = {|
  variables: CommentsItemQueryVariables,
  response: CommentsItemQueryResponse,
|};
*/


/*
query CommentsItemQuery(
  $id: ID!
  $isRepliesExpanded: Boolean!
) {
  comment(id: $id) {
    id
    ...CommentsItem_comment_2aaWJ5
  }
}

fragment CommentsItem_comment_2aaWJ5 on Comment {
  id
  myPerms
  body
  document {
    revisionsCount
    id
  }
  revisionCreated {
    createdAt
    author {
      id
      username
      avatar(width: 40, height: 40) {
        url
      }
      ...ProfileLink_user
    }
    id
  }
  voting {
    ...VotingButtons_voting
    id
  }
  commenting {
    id
    count
    ...CommentsList_commenting @include(if: $isRepliesExpanded)
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}

fragment VotingButtons_voting on Voting {
  id
  countUps
  countDowns
  sumValues
  mine {
    id
    value
  }
}

fragment CommentsList_commenting on Commenting {
  id
  comments(first: 30) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        ...CommentsItem_comment
        __typename
      }
      cursor
    }
  }
}

fragment CommentsItem_comment on Comment {
  id
  myPerms
  body
  document {
    revisionsCount
    id
  }
  revisionCreated {
    createdAt
    author {
      id
      username
      avatar(width: 40, height: 40) {
        url
      }
      ...ProfileLink_user
    }
    id
  }
  voting {
    ...VotingButtons_voting
    id
  }
  commenting {
    id
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isRepliesExpanded",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "myPerms",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "document",
  "storageKey": null,
  "args": null,
  "concreteType": "Document",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "revisionsCount",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/)
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "revisionCreated",
  "storageKey": null,
  "args": null,
  "concreteType": "Revision",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "author",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "avatar",
          "storageKey": "avatar(height:40,width:40)",
          "args": [
            {
              "kind": "Literal",
              "name": "height",
              "value": 40
            },
            {
              "kind": "Literal",
              "name": "width",
              "value": 40
            }
          ],
          "concreteType": "File",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "reputation",
          "args": null,
          "storageKey": null
        }
      ]
    },
    (v2/*: any*/)
  ]
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "voting",
  "storageKey": null,
  "args": null,
  "concreteType": "Voting",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countUps",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countDowns",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sumValues",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mine",
      "storageKey": null,
      "args": null,
      "concreteType": "Vote",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CommentsItemQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "comment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Comment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "CommentsItem_comment",
            "args": [
              {
                "kind": "Variable",
                "name": "isRepliesExpanded",
                "variableName": "isRepliesExpanded"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CommentsItemQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "comment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Comment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commenting",
            "storageKey": null,
            "args": null,
            "concreteType": "Commenting",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/),
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "isRepliesExpanded",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "comments",
                    "storageKey": "comments(first:30)",
                    "args": (v9/*: any*/),
                    "concreteType": "CommentConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "pageInfo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endCursor",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hasNextPage",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Comment",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "commenting",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Commenting",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v8/*: any*/)
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "__typename",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cursor",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedHandle",
                    "alias": null,
                    "name": "comments",
                    "args": (v9/*: any*/),
                    "handle": "connection",
                    "key": "CommentsList_comments",
                    "filters": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CommentsItemQuery",
    "id": null,
    "text": "query CommentsItemQuery(\n  $id: ID!\n  $isRepliesExpanded: Boolean!\n) {\n  comment(id: $id) {\n    id\n    ...CommentsItem_comment_2aaWJ5\n  }\n}\n\nfragment CommentsItem_comment_2aaWJ5 on Comment {\n  id\n  myPerms\n  body\n  document {\n    revisionsCount\n    id\n  }\n  revisionCreated {\n    createdAt\n    author {\n      id\n      username\n      avatar(width: 40, height: 40) {\n        url\n      }\n      ...ProfileLink_user\n    }\n    id\n  }\n  voting {\n    ...VotingButtons_voting\n    id\n  }\n  commenting {\n    id\n    count\n    ...CommentsList_commenting @include(if: $isRepliesExpanded)\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n\nfragment VotingButtons_voting on Voting {\n  id\n  countUps\n  countDowns\n  sumValues\n  mine {\n    id\n    value\n  }\n}\n\nfragment CommentsList_commenting on Commenting {\n  id\n  comments(first: 30) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...CommentsItem_comment\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment CommentsItem_comment on Comment {\n  id\n  myPerms\n  body\n  document {\n    revisionsCount\n    id\n  }\n  revisionCreated {\n    createdAt\n    author {\n      id\n      username\n      avatar(width: 40, height: 40) {\n        url\n      }\n      ...ProfileLink_user\n    }\n    id\n  }\n  voting {\n    ...VotingButtons_voting\n    id\n  }\n  commenting {\n    id\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24e2f6fc587b6342aad93225abadd3cc';
module.exports = node;
