/**
 * @flow
 * @relayHash 6d993b54a074674a8aecbc240e2b545d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Edibility = "BAD" | "EXCELLENT" | "FAIR" | "GOOD" | "POOR" | "SOME" | "%future added value";
export type FlowerColor = "BLACK" | "BLUE" | "BROWN" | "CREAM" | "GREEN" | "LIGHT_BLUE" | "LILAC" | "NUT_BROWN" | "ORANGE" | "PINK" | "PURPLE" | "RED" | "WHITE" | "WINE" | "YELLOW" | "%future added value";
export type FlowerType = "INFLORESCENCE" | "PSEUDANTHIUM" | "SOLITARY" | "%future added value";
export type GrowthHabit = "GRAMINOID" | "HERB" | "LICHENOUS" | "NONVASCULAR" | "SHRUB" | "SUBSHRUB" | "SUCCULENT" | "TREE" | "VINE" | "%future added value";
export type GrowthRate = "FAST" | "MODERATE" | "SLOW" | "%future added value";
export type Rank = "CLASS" | "FAMILY" | "GENUS" | "INFRASPECIES" | "KINGDOM" | "ORDER" | "PHYLUM" | "SPECIES" | "VARIETY" | "%future added value";
export type Succession = "CLIMAX" | "PRIMARY" | "SECONDARY" | "%future added value";
export type Threatened = "CONSERVATION_DEPENDENT" | "CRITICALLY_ENDANGERED" | "DATA_DEFICIENT" | "ENDANGERED" | "EXTINCT" | "EXTINCT_IN_THE_WILD" | "LEAST_CONCERN" | "NEAR_THREATENED" | "NOT_EVALUATED" | "VULNERABLE" | "%future added value";
export type LifeNodeEditInput = {|
  id: string,
  title?: ?string,
  description?: ?string,
  rank?: ?Rank,
  edibility?: ?Edibility,
  flowerColors?: ?$ReadOnlyArray<?FlowerColor>,
  flowerTypes?: ?$ReadOnlyArray<?FlowerType>,
  growthHabit?: ?$ReadOnlyArray<?GrowthHabit>,
  growthRate?: ?$ReadOnlyArray<?GrowthRate>,
  succession?: ?Succession,
  threatened?: ?Threatened,
  sun?: ?DecimalRangeType,
  spread?: ?DecimalRangeType,
  height?: ?DecimalRangeType,
  parent?: ?string,
  gbifId?: ?number,
  commonNames?: ?$ReadOnlyArray<?CommonNameInput>,
  imagesToAdd?: ?$ReadOnlyArray<?ImageInput>,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type DecimalRangeType = {|
  lower?: ?any,
  upper?: ?any,
|};
export type CommonNameInput = {|
  id?: ?string,
  name: string,
  language?: ?string,
|};
export type ImageInput = {|
  field: string,
  description?: ?string,
|};
export type PlantEditMutationVariables = {|
  input: LifeNodeEditInput
|};
export type PlantEditMutationResponse = {|
  +lifeNodeEdit: ?{|
    +lifeNode: ?{|
      +id: string
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type PlantEditMutation = {|
  variables: PlantEditMutationVariables,
  response: PlantEditMutationResponse,
|};
*/


/*
mutation PlantEditMutation(
  $input: LifeNodeEditInput!
) {
  lifeNodeEdit(input: $input) {
    lifeNode {
      id
    }
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "LifeNodeEditInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "lifeNodeEdit",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LifeNodeEditPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lifeNode",
        "storageKey": null,
        "args": null,
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantEditMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantEditMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PlantEditMutation",
    "id": null,
    "text": "mutation PlantEditMutation(\n  $input: LifeNodeEditInput!\n) {\n  lifeNodeEdit(input: $input) {\n    lifeNode {\n      id\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '729766669220243ca96a82f457636d55';
module.exports = node;
