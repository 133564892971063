/**
 * @flow
 * @relayHash e4ace822986d9262358887cf20fa7c08
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommonNameItem_commonName$ref = any;
export type CommonNameAddInput = {|
  lifeNode: string,
  name: string,
  language?: ?string,
  country?: ?string,
  region?: ?string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type AddCommonNameMutationVariables = {|
  input: CommonNameAddInput
|};
export type AddCommonNameMutationResponse = {|
  +lifeNodeCommonNameAdd: ?{|
    +commonName: ?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: CommonNameItem_commonName$ref,
      |}
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type AddCommonNameMutation = {|
  variables: AddCommonNameMutationVariables,
  response: AddCommonNameMutationResponse,
|};
*/


/*
mutation AddCommonNameMutation(
  $input: CommonNameAddInput!
) {
  lifeNodeCommonNameAdd(input: $input) {
    commonName {
      node {
        id
        ...CommonNameItem_commonName
      }
    }
    errors {
      code
      location
      message
    }
  }
}

fragment CommonNameItem_commonName on CommonName {
  id
  name
  language
  voting {
    ...VotingButtons_voting
    id
  }
}

fragment VotingButtons_voting on Voting {
  id
  countUps
  countDowns
  sumValues
  mine {
    id
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CommonNameAddInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddCommonNameMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lifeNodeCommonNameAdd",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CommonNameAddPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commonName",
            "storageKey": null,
            "args": null,
            "concreteType": "CommonNameEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CommonName",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "CommonNameItem_commonName",
                    "args": null
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddCommonNameMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lifeNodeCommonNameAdd",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CommonNameAddPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commonName",
            "storageKey": null,
            "args": null,
            "concreteType": "CommonNameEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CommonName",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "language",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "voting",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Voting",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "countUps",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "countDowns",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "sumValues",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "mine",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Vote",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "value",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddCommonNameMutation",
    "id": null,
    "text": "mutation AddCommonNameMutation(\n  $input: CommonNameAddInput!\n) {\n  lifeNodeCommonNameAdd(input: $input) {\n    commonName {\n      node {\n        id\n        ...CommonNameItem_commonName\n      }\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n\nfragment CommonNameItem_commonName on CommonName {\n  id\n  name\n  language\n  voting {\n    ...VotingButtons_voting\n    id\n  }\n}\n\nfragment VotingButtons_voting on Voting {\n  id\n  countUps\n  countDowns\n  sumValues\n  mine {\n    id\n    value\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd94566b27414f10a6716b5ef43cb1ec';
module.exports = node;
