/**
 * @flow
 * @relayHash e7931198eed4c96ceafae8b0e5ff0ff0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PlantLink_plant$ref = any;
type ProfileLink_user$ref = any;
export type OccurrencesMapQueryVariables = {|
  count: number,
  identity?: ?string,
  author?: ?string,
|};
export type OccurrencesMapQueryResponse = {|
  +viewer: ?{|
    +allOccurrences: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +identity: ?{|
            +id: string,
            +idInt: ?number,
            +title: string,
            +commonName: ?{|
              +name: string
            |},
            +rankDisplay: ?string,
            +images: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +smallImage: ?{|
                    +url: string
                  |},
                |}
              |}>
            |},
            +$fragmentRefs: PlantLink_plant$ref,
          |},
          +location: ?{|
            +type: ?string,
            +coordinates: ?any,
          |},
          +author: ?{|
            +id: string,
            +$fragmentRefs: ProfileLink_user$ref,
          |},
          +revisionCreated: ?{|
            +createdAt: any
          |},
        |}
      |}>
    |}
  |}
|};
export type OccurrencesMapQuery = {|
  variables: OccurrencesMapQueryVariables,
  response: OccurrencesMapQueryResponse,
|};
*/


/*
query OccurrencesMapQuery(
  $count: Int!
  $identity: ID
  $author: ID
) {
  viewer {
    allOccurrences(first: $count, identity: $identity, author: $author, isIdentityNull: false) {
      edges {
        node {
          id
          identity {
            id
            idInt
            title
            commonName {
              name
              id
            }
            ...PlantLink_plant
            rankDisplay
            images(first: 1) {
              edges {
                node {
                  id
                  smallImage: image(width: 440, height: 520) {
                    url
                  }
                }
              }
            }
          }
          location {
            type
            coordinates
          }
          author {
            id
            ...ProfileLink_user
          }
          revisionCreated {
            createdAt
            id
          }
        }
      }
    }
    id
  }
}

fragment PlantLink_plant on LifeNode {
  id
  idInt
  title
  slug
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "identity",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "author",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "author",
    "variableName": "author"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "identity",
    "variableName": "identity"
  },
  {
    "kind": "Literal",
    "name": "isIdentityNull",
    "value": false
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idInt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rankDisplay",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "images",
  "storageKey": "images(first:1)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "ImageConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ImageEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Image",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "LinkedField",
              "alias": "smallImage",
              "name": "image",
              "storageKey": "image(height:520,width:440)",
              "args": [
                {
                  "kind": "Literal",
                  "name": "height",
                  "value": 520
                },
                {
                  "kind": "Literal",
                  "name": "width",
                  "value": 440
                }
              ],
              "concreteType": "File",
              "plural": false,
              "selections": (v7/*: any*/)
            }
          ]
        }
      ]
    }
  ]
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "location",
  "storageKey": null,
  "args": null,
  "concreteType": "GeometryObjectType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "coordinates",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OccurrencesMapQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allOccurrences",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "OccurrenceConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OccurrenceEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Occurrence",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "identity",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LifeNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "commonName",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommonName",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ]
                          },
                          (v6/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "PlantLink_plant",
                            "args": null
                          }
                        ]
                      },
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "ProfileLink_user",
                            "args": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "revisionCreated",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Revision",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OccurrencesMapQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allOccurrences",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "OccurrenceConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OccurrenceEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Occurrence",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "identity",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LifeNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "commonName",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommonName",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "slug",
                            "args": null,
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v8/*: any*/)
                        ]
                      },
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "reputation",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "avatar",
                            "storageKey": "avatar(height:40,width:40)",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "height",
                                "value": 40
                              },
                              {
                                "kind": "Literal",
                                "name": "width",
                                "value": 40
                              }
                            ],
                            "concreteType": "File",
                            "plural": false,
                            "selections": (v7/*: any*/)
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "revisionCreated",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Revision",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OccurrencesMapQuery",
    "id": null,
    "text": "query OccurrencesMapQuery(\n  $count: Int!\n  $identity: ID\n  $author: ID\n) {\n  viewer {\n    allOccurrences(first: $count, identity: $identity, author: $author, isIdentityNull: false) {\n      edges {\n        node {\n          id\n          identity {\n            id\n            idInt\n            title\n            commonName {\n              name\n              id\n            }\n            ...PlantLink_plant\n            rankDisplay\n            images(first: 1) {\n              edges {\n                node {\n                  id\n                  smallImage: image(width: 440, height: 520) {\n                    url\n                  }\n                }\n              }\n            }\n          }\n          location {\n            type\n            coordinates\n          }\n          author {\n            id\n            ...ProfileLink_user\n          }\n          revisionCreated {\n            createdAt\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment PlantLink_plant on LifeNode {\n  id\n  idInt\n  title\n  slug\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64d812fb6ddb9e742ef96e9525bbe7b0';
module.exports = node;
