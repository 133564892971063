/**
 * @flow
 * @relayHash d72d9442e0686336cc1998fc70eb032d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommentsList_commenting$ref = any;
type ProfileLink_user$ref = any;
type VotingButtons_voting$ref = any;
export type PostQueryVariables = {|
  url: string
|};
export type PostQueryResponse = {|
  +post: ?{|
    +id: string,
    +url: string,
    +title: string,
    +body: string,
    +publishedAt: ?any,
    +myPerms: ?$ReadOnlyArray<?string>,
    +document: ?{|
      +revisionsCount: number
    |},
    +tags: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +slug: string,
          +title: string,
        |}
      |}>
    |},
    +revisionCreated: ?{|
      +author: ?{|
        +$fragmentRefs: ProfileLink_user$ref
      |}
    |},
    +voting: ?{|
      +$fragmentRefs: VotingButtons_voting$ref
    |},
    +commenting: ?{|
      +$fragmentRefs: CommentsList_commenting$ref
    |},
  |}
|};
export type PostQuery = {|
  variables: PostQueryVariables,
  response: PostQueryResponse,
|};
*/


/*
query PostQuery(
  $url: String!
) {
  post: postByUrl(url: $url) {
    id
    url
    title
    body
    publishedAt
    myPerms
    document {
      revisionsCount
      id
    }
    tags(first: 50) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    revisionCreated {
      author {
        ...ProfileLink_user
        id
      }
      id
    }
    voting {
      ...VotingButtons_voting
      id
    }
    commenting {
      ...CommentsList_commenting
      id
    }
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}

fragment VotingButtons_voting on Voting {
  id
  countUps
  countDowns
  sumValues
  mine {
    id
    value
  }
}

fragment CommentsList_commenting on Commenting {
  id
  comments(first: 30) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        ...CommentsItem_comment
        __typename
      }
      cursor
    }
  }
}

fragment CommentsItem_comment on Comment {
  id
  myPerms
  body
  document {
    revisionsCount
    id
  }
  revisionCreated {
    createdAt
    author {
      id
      username
      avatar(width: 40, height: 40) {
        url
      }
      ...ProfileLink_user
    }
    id
  }
  voting {
    ...VotingButtons_voting
    id
  }
  commenting {
    id
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "url",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "url",
    "variableName": "url"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "myPerms",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionsCount",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": "tags(first:50)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 50
    }
  ],
  "concreteType": "TagConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TagEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Tag",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "slug",
              "args": null,
              "storageKey": null
            },
            (v4/*: any*/)
          ]
        }
      ]
    }
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "document",
  "storageKey": null,
  "args": null,
  "concreteType": "Document",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v2/*: any*/)
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reputation",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "avatar",
  "storageKey": "avatar(height:40,width:40)",
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 40
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 40
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ]
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "voting",
  "storageKey": null,
  "args": null,
  "concreteType": "Voting",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countUps",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countDowns",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sumValues",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mine",
      "storageKey": null,
      "args": null,
      "concreteType": "Vote",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PostQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "post",
        "name": "postByUrl",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "Document",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ]
          },
          (v9/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "revisionCreated",
            "storageKey": null,
            "args": null,
            "concreteType": "Revision",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "ProfileLink_user",
                    "args": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "voting",
            "storageKey": null,
            "args": null,
            "concreteType": "Voting",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "VotingButtons_voting",
                "args": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commenting",
            "storageKey": null,
            "args": null,
            "concreteType": "Commenting",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "CommentsList_commenting",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PostQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "post",
        "name": "postByUrl",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v10/*: any*/),
          (v9/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "revisionCreated",
            "storageKey": null,
            "args": null,
            "concreteType": "Revision",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ]
              },
              (v2/*: any*/)
            ]
          },
          (v15/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commenting",
            "storageKey": null,
            "args": null,
            "concreteType": "Commenting",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "comments",
                "storageKey": "comments(first:30)",
                "args": (v16/*: any*/),
                "concreteType": "CommentConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endCursor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasNextPage",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Comment",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/),
                          (v5/*: any*/),
                          (v10/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "revisionCreated",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Revision",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "createdAt",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "author",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "User",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v11/*: any*/),
                                  (v14/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/)
                                ]
                              },
                              (v2/*: any*/)
                            ]
                          },
                          (v15/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "commenting",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Commenting",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "count",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "comments",
                "args": (v16/*: any*/),
                "handle": "connection",
                "key": "CommentsList_comments",
                "filters": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PostQuery",
    "id": null,
    "text": "query PostQuery(\n  $url: String!\n) {\n  post: postByUrl(url: $url) {\n    id\n    url\n    title\n    body\n    publishedAt\n    myPerms\n    document {\n      revisionsCount\n      id\n    }\n    tags(first: 50) {\n      edges {\n        node {\n          id\n          slug\n          title\n        }\n      }\n    }\n    revisionCreated {\n      author {\n        ...ProfileLink_user\n        id\n      }\n      id\n    }\n    voting {\n      ...VotingButtons_voting\n      id\n    }\n    commenting {\n      ...CommentsList_commenting\n      id\n    }\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n\nfragment VotingButtons_voting on Voting {\n  id\n  countUps\n  countDowns\n  sumValues\n  mine {\n    id\n    value\n  }\n}\n\nfragment CommentsList_commenting on Commenting {\n  id\n  comments(first: 30) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...CommentsItem_comment\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment CommentsItem_comment on Comment {\n  id\n  myPerms\n  body\n  document {\n    revisionsCount\n    id\n  }\n  revisionCreated {\n    createdAt\n    author {\n      id\n      username\n      avatar(width: 40, height: 40) {\n        url\n      }\n      ...ProfileLink_user\n    }\n    id\n  }\n  voting {\n    ...VotingButtons_voting\n    id\n  }\n  commenting {\n    id\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6d19dc84565629bed0590a566ead730b';
module.exports = node;
