/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type VotingButtons_voting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommonNameItem_commonName$ref: FragmentReference;
declare export opaque type CommonNameItem_commonName$fragmentType: CommonNameItem_commonName$ref;
export type CommonNameItem_commonName = {|
  +id: string,
  +name: string,
  +language: ?string,
  +voting: ?{|
    +$fragmentRefs: VotingButtons_voting$ref
  |},
  +$refType: CommonNameItem_commonName$ref,
|};
export type CommonNameItem_commonName$data = CommonNameItem_commonName;
export type CommonNameItem_commonName$key = {
  +$data?: CommonNameItem_commonName$data,
  +$fragmentRefs: CommonNameItem_commonName$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CommonNameItem_commonName",
  "type": "CommonName",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "language",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "voting",
      "storageKey": null,
      "args": null,
      "concreteType": "Voting",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "VotingButtons_voting",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c5260fe3861a52308ac1d90a73ac9f21';
module.exports = node;
