/**
 * @flow
 * @relayHash bf3cc7e7195d6a6aeee87630c581a445
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OccurrenceDeleteInput = {|
  id: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type OccurrenceDeleteMutationVariables = {|
  input: OccurrenceDeleteInput
|};
export type OccurrenceDeleteMutationResponse = {|
  +occurrenceDelete: ?{|
    +occurenceDeletedID: string,
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type OccurrenceDeleteMutation = {|
  variables: OccurrenceDeleteMutationVariables,
  response: OccurrenceDeleteMutationResponse,
|};
*/


/*
mutation OccurrenceDeleteMutation(
  $input: OccurrenceDeleteInput!
) {
  occurrenceDelete(input: $input) {
    occurenceDeletedID
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "OccurrenceDeleteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "occurrenceDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OccurrenceDeletePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "occurenceDeletedID",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OccurrenceDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OccurrenceDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "OccurrenceDeleteMutation",
    "id": null,
    "text": "mutation OccurrenceDeleteMutation(\n  $input: OccurrenceDeleteInput!\n) {\n  occurrenceDelete(input: $input) {\n    occurenceDeletedID\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '950e329d17499f0be1a35cb29ede8380';
module.exports = node;
