/**
 * @flow
 * @relayHash 87a7c739e539ed16150108d48fc63014
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Edibility = "BAD" | "EXCELLENT" | "FAIR" | "GOOD" | "POOR" | "SOME" | "%future added value";
export type FlowerColor = "BLACK" | "BLUE" | "BROWN" | "CREAM" | "GREEN" | "LIGHT_BLUE" | "LILAC" | "NUT_BROWN" | "ORANGE" | "PINK" | "PURPLE" | "RED" | "WHITE" | "WINE" | "YELLOW" | "%future added value";
export type FlowerType = "INFLORESCENCE" | "PSEUDANTHIUM" | "SOLITARY" | "%future added value";
export type GrowthHabit = "GRAMINOID" | "HERB" | "LICHENOUS" | "NONVASCULAR" | "SHRUB" | "SUBSHRUB" | "SUCCULENT" | "TREE" | "VINE" | "%future added value";
export type GrowthRate = "FAST" | "MODERATE" | "SLOW" | "%future added value";
export type Rank = "CLASS" | "FAMILY" | "GENUS" | "INFRASPECIES" | "KINGDOM" | "ORDER" | "PHYLUM" | "SPECIES" | "VARIETY" | "%future added value";
export type Succession = "CLIMAX" | "PRIMARY" | "SECONDARY" | "%future added value";
export type Threatened = "CONSERVATION_DEPENDENT" | "CRITICALLY_ENDANGERED" | "DATA_DEFICIENT" | "ENDANGERED" | "EXTINCT" | "EXTINCT_IN_THE_WILD" | "LEAST_CONCERN" | "NEAR_THREATENED" | "NOT_EVALUATED" | "VULNERABLE" | "%future added value";
export type LifeNodeCreateInput = {|
  title: string,
  description?: ?string,
  rank: Rank,
  edibility?: ?Edibility,
  flowerColors?: ?$ReadOnlyArray<?FlowerColor>,
  flowerTypes?: ?$ReadOnlyArray<?FlowerType>,
  growthHabit?: ?$ReadOnlyArray<?GrowthHabit>,
  growthRate?: ?$ReadOnlyArray<?GrowthRate>,
  succession?: ?Succession,
  threatened?: ?Threatened,
  sun?: ?DecimalRangeType,
  spread?: ?DecimalRangeType,
  height?: ?DecimalRangeType,
  parent?: ?string,
  gbifId?: ?number,
  commonNames?: ?$ReadOnlyArray<?CommonNameInput>,
  imagesToAdd?: ?$ReadOnlyArray<?ImageInput>,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type DecimalRangeType = {|
  lower?: ?any,
  upper?: ?any,
|};
export type CommonNameInput = {|
  id?: ?string,
  name: string,
  language?: ?string,
|};
export type ImageInput = {|
  field: string,
  description?: ?string,
|};
export type PlantAddMutationVariables = {|
  input: LifeNodeCreateInput
|};
export type PlantAddMutationResponse = {|
  +lifeNodeCreate: ?{|
    +lifeNode: ?{|
      +id: string,
      +idInt: ?number,
      +slug: ?string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type PlantAddMutation = {|
  variables: PlantAddMutationVariables,
  response: PlantAddMutationResponse,
|};
*/


/*
mutation PlantAddMutation(
  $input: LifeNodeCreateInput!
) {
  lifeNodeCreate(input: $input) {
    lifeNode {
      id
      idInt
      slug
    }
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "LifeNodeCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "lifeNodeCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LifeNodeCreatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lifeNode",
        "storageKey": null,
        "args": null,
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "idInt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantAddMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantAddMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PlantAddMutation",
    "id": null,
    "text": "mutation PlantAddMutation(\n  $input: LifeNodeCreateInput!\n) {\n  lifeNodeCreate(input: $input) {\n    lifeNode {\n      id\n      idInt\n      slug\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bbc7b53d77abeeb3d6295f79110996a1';
module.exports = node;
