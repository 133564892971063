/**
 * @flow
 * @relayHash fb50009ad11839dd7d6bd6106fdbddea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserOccurrenceMapQueryVariables = {|
  username: string
|};
export type UserOccurrenceMapQueryResponse = {|
  +user: ?{|
    +id: string,
    +username: string,
    +firstName: string,
  |}
|};
export type UserOccurrenceMapQuery = {|
  variables: UserOccurrenceMapQueryVariables,
  response: UserOccurrenceMapQueryResponse,
|};
*/


/*
query UserOccurrenceMapQuery(
  $username: String!
) {
  user: userByUsername(username: $username) {
    id
    username
    firstName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "username",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "user",
    "name": "userByUsername",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "User",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserOccurrenceMapQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UserOccurrenceMapQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "UserOccurrenceMapQuery",
    "id": null,
    "text": "query UserOccurrenceMapQuery(\n  $username: String!\n) {\n  user: userByUsername(username: $username) {\n    id\n    username\n    firstName\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e86a9442f62a356bdd057c46b5014ef';
module.exports = node;
