/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfileLink_user$ref: FragmentReference;
declare export opaque type ProfileLink_user$fragmentType: ProfileLink_user$ref;
export type ProfileLink_user = {|
  +id: string,
  +username: string,
  +firstName: string,
  +reputation: number,
  +avatar: ?{|
    +url: string
  |},
  +$refType: ProfileLink_user$ref,
|};
export type ProfileLink_user$data = ProfileLink_user;
export type ProfileLink_user$key = {
  +$data?: ProfileLink_user$data,
  +$fragmentRefs: ProfileLink_user$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProfileLink_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reputation",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "avatar",
      "storageKey": "avatar(height:40,width:40)",
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 40
        },
        {
          "kind": "Literal",
          "name": "width",
          "value": 40
        }
      ],
      "concreteType": "File",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '82806fbf66e78e2c3d6d40866264cef3';
module.exports = node;
