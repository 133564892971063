/**
 * @flow
 * @relayHash 4bdb2d708b41198acc39d232dbe84252
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SocialAuthInput = {|
  provider: string,
  accessToken: string,
  clientMutationId?: ?string,
|};
export type AuthSocialMutationVariables = {|
  input: SocialAuthInput
|};
export type AuthSocialMutationResponse = {|
  +socialAuth: ?{|
    +social: ?{|
      +id: string
    |},
    +clientMutationId: ?string,
  |}
|};
export type AuthSocialMutation = {|
  variables: AuthSocialMutationVariables,
  response: AuthSocialMutationResponse,
|};
*/


/*
mutation AuthSocialMutation(
  $input: SocialAuthInput!
) {
  socialAuth(input: $input) {
    social {
      id
    }
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SocialAuthInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "socialAuth",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SocialAuthPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "social",
        "storageKey": null,
        "args": null,
        "concreteType": "SocialNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AuthSocialMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AuthSocialMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AuthSocialMutation",
    "id": null,
    "text": "mutation AuthSocialMutation(\n  $input: SocialAuthInput!\n) {\n  socialAuth(input: $input) {\n    social {\n      id\n    }\n    clientMutationId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '330dee796df82cf7a2b7e374bdd5a3cd';
module.exports = node;
