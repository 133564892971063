/**
 * @flow
 * @relayHash 707095066e8e5bc9e12a8a60b7324791
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeauthenticateInput = {|
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type DeauthMutationVariables = {|
  input: DeauthenticateInput
|};
export type DeauthMutationResponse = {|
  +deauthenticate: ?{|
    +viewer: ?{|
      +id: string,
      +me: ?{|
        +isAuthenticated: ?boolean
      |},
    |}
  |}
|};
export type DeauthMutation = {|
  variables: DeauthMutationVariables,
  response: DeauthMutationResponse,
|};
*/


/*
mutation DeauthMutation(
  $input: DeauthenticateInput!
) {
  deauthenticate(input: $input) {
    viewer {
      id
      me {
        isAuthenticated
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeauthenticateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAuthenticated",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeauthMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deauthenticate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeauthenticatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Query",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "me",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeauthMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deauthenticate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeauthenticatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Query",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "me",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeauthMutation",
    "id": null,
    "text": "mutation DeauthMutation(\n  $input: DeauthenticateInput!\n) {\n  deauthenticate(input: $input) {\n    viewer {\n      id\n      me {\n        isAuthenticated\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2fec587de1ddd8621375d4ccedde769c';
module.exports = node;
