/**
 * @flow
 * @relayHash 5902d552f07c52b9e6be0dc3ade656e6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RevisionRevertInput = {|
  id: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type RevisionRevertMutationVariables = {|
  input: RevisionRevertInput
|};
export type RevisionRevertMutationResponse = {|
  +revisionRevert: ?{|
    +node: ?{|
      +id: string,
      +revision?: {|
        +id: string,
        +isTip: ?boolean,
      |},
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type RevisionRevertMutation = {|
  variables: RevisionRevertMutationVariables,
  response: RevisionRevertMutationResponse,
|};
*/


/*
mutation RevisionRevertMutation(
  $input: RevisionRevertInput!
) {
  revisionRevert(input: $input) {
    node {
      __typename
      id
      ... on LifeNode {
        revision {
          id
          isTip
        }
      }
      ... on Page {
        revision {
          id
          isTip
        }
      }
      ... on Post {
        revision {
          id
          isTip
        }
      }
      ... on Comment {
        revision {
          id
          isTip
        }
      }
    }
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RevisionRevertInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "revision",
    "storageKey": null,
    "args": null,
    "concreteType": "Revision",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isTip",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v4 = {
  "kind": "InlineFragment",
  "type": "LifeNode",
  "selections": (v3/*: any*/)
},
v5 = {
  "kind": "InlineFragment",
  "type": "Page",
  "selections": (v3/*: any*/)
},
v6 = {
  "kind": "InlineFragment",
  "type": "Post",
  "selections": (v3/*: any*/)
},
v7 = {
  "kind": "InlineFragment",
  "type": "Comment",
  "selections": (v3/*: any*/)
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RevisionRevertMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "revisionRevert",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RevisionRevertPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RevisionRevertMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "revisionRevert",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RevisionRevertPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "RevisionRevertMutation",
    "id": null,
    "text": "mutation RevisionRevertMutation(\n  $input: RevisionRevertInput!\n) {\n  revisionRevert(input: $input) {\n    node {\n      __typename\n      id\n      ... on LifeNode {\n        revision {\n          id\n          isTip\n        }\n      }\n      ... on Page {\n        revision {\n          id\n          isTip\n        }\n      }\n      ... on Post {\n        revision {\n          id\n          isTip\n        }\n      }\n      ... on Comment {\n        revision {\n          id\n          isTip\n        }\n      }\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4874cbb1ec13fc6246e4a91267a9fa10';
module.exports = node;
