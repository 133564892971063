/**
 * @flow
 * @relayHash 7c5a89b4dea120b8c499d08cb7a9a880
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PostEditInput = {|
  id: string,
  url: string,
  title: string,
  body: string,
  summary?: ?string,
  publishedAt: any,
  mainImage?: ?string,
  tags?: ?string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type PostEditMutationVariables = {|
  input: PostEditInput
|};
export type PostEditMutationResponse = {|
  +postEdit: ?{|
    +post: ?{|
      +id: string,
      +title: string,
      +url: string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type PostEditMutation = {|
  variables: PostEditMutationVariables,
  response: PostEditMutationResponse,
|};
*/


/*
mutation PostEditMutation(
  $input: PostEditInput!
) {
  postEdit(input: $input) {
    post {
      id
      title
      url
    }
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PostEditInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "postEdit",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PostEditPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "post",
        "storageKey": null,
        "args": null,
        "concreteType": "Post",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PostEditMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PostEditMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PostEditMutation",
    "id": null,
    "text": "mutation PostEditMutation(\n  $input: PostEditInput!\n) {\n  postEdit(input: $input) {\n    post {\n      id\n      title\n      url\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '784535d82933c778ca3493168105e1c3';
module.exports = node;
