/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PlantItem_lifeNode$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserPlantList_viewer$ref: FragmentReference;
declare export opaque type UserPlantList_viewer$fragmentType: UserPlantList_viewer$ref;
export type UserPlantList_viewer = {|
  +id: string,
  +username: string,
  +firstName: string,
  +wishList: ?{|
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +plant: ?{|
          +id: string,
          +$fragmentRefs: PlantItem_lifeNode$ref,
        |},
      |}
    |}>,
  |},
  +$refType: UserPlantList_viewer$ref,
|};
export type UserPlantList_viewer$data = UserPlantList_viewer;
export type UserPlantList_viewer$key = {
  +$data?: UserPlantList_viewer$data,
  +$fragmentRefs: UserPlantList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "UserPlantList_viewer",
  "type": "User",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "wishList"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 30
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "wishList",
      "name": "__UserPlantList_wishList_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "WishItemConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "WishItemEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "WishItem",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "plant",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "LifeNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "FragmentSpread",
                      "name": "PlantItem_lifeNode",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6960f5a42b144cbefe5e735064688e51';
module.exports = node;
