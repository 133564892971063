/**
 * @flow
 * @relayHash 401f9de31d9a799d462f20a3af486b5e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PlantSelectField_viewer$ref = any;
export type PlantSelectFieldRenderQueryVariables = {||};
export type PlantSelectFieldRenderQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +$fragmentRefs: PlantSelectField_viewer$ref,
  |}
|};
export type PlantSelectFieldRenderQuery = {|
  variables: PlantSelectFieldRenderQueryVariables,
  response: PlantSelectFieldRenderQueryResponse,
|};
*/


/*
query PlantSelectFieldRenderQuery {
  viewer {
    id
    ...PlantSelectField_viewer
  }
}

fragment PlantSelectField_viewer on Query {
  id
  allLifeNode(first: 0) {
    edges {
      node {
        id
        title
        rankDisplay
        images(first: 1) {
          edges {
            node {
              id
              mainImage: image(width: 200, height: 200) {
                url
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantSelectFieldRenderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "PlantSelectField_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantSelectFieldRenderQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allLifeNode",
            "storageKey": "allLifeNode(first:0)",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 0
              }
            ],
            "concreteType": "LifeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LifeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LifeNode",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rankDisplay",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "images",
                        "storageKey": "images(first:1)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          }
                        ],
                        "concreteType": "ImageConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ImageEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Image",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": "mainImage",
                                    "name": "image",
                                    "storageKey": "image(height:200,width:200)",
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "height",
                                        "value": 200
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "width",
                                        "value": 200
                                      }
                                    ],
                                    "concreteType": "File",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "url",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PlantSelectFieldRenderQuery",
    "id": null,
    "text": "query PlantSelectFieldRenderQuery {\n  viewer {\n    id\n    ...PlantSelectField_viewer\n  }\n}\n\nfragment PlantSelectField_viewer on Query {\n  id\n  allLifeNode(first: 0) {\n    edges {\n      node {\n        id\n        title\n        rankDisplay\n        images(first: 1) {\n          edges {\n            node {\n              id\n              mainImage: image(width: 200, height: 200) {\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9e803ee66bb373c6bf84b9d1c7369413';
module.exports = node;
