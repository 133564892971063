/**
 * @flow
 * @relayHash f10304516b8efa467fc3bef4849ced15
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ImageThumbnail_image$ref = any;
export type PostEditQueryVariables = {|
  id: string
|};
export type PostEditQueryResponse = {|
  +post: ?{|
    +id: string,
    +url: string,
    +title: string,
    +body: string,
    +summary: string,
    +mainImage: ?{|
      +id: string
    |},
    +publishedAt: ?any,
    +tags: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +slug: string,
          +title: string,
        |}
      |}>
    |},
    +imaging: ?{|
      +id: string,
      +images: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +smallImage: ?{|
              +url: string
            |},
            +$fragmentRefs: ImageThumbnail_image$ref,
          |}
        |}>
      |},
    |},
  |}
|};
export type PostEditQuery = {|
  variables: PostEditQueryVariables,
  response: PostEditQueryResponse,
|};
*/


/*
query PostEditQuery(
  $id: ID!
) {
  post(id: $id) {
    id
    url
    title
    body
    summary
    mainImage {
      id
    }
    publishedAt
    tags(first: 50) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    imaging {
      id
      images(first: 100) {
        edges {
          node {
            id
            smallImage: image(width: 200, height: 200) {
              url
            }
            ...ImageThumbnail_image
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}

fragment ImageThumbnail_image on Image {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "mainImage",
  "storageKey": null,
  "args": null,
  "concreteType": "Image",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedAt",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": "tags(first:50)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 50
    }
  ],
  "concreteType": "TagConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TagEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Tag",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "slug",
              "args": null,
              "storageKey": null
            },
            (v4/*: any*/)
          ]
        }
      ]
    }
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": "smallImage",
  "name": "image",
  "storageKey": "image(height:200,width:200)",
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 200
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 200
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PostEditQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "post",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "imaging",
            "storageKey": null,
            "args": null,
            "concreteType": "Imaging",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": "images",
                "name": "__List_images_connection",
                "storageKey": null,
                "args": null,
                "concreteType": "ImageConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ImageEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Image",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "ImageThumbnail_image",
                            "args": null
                          }
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PostEditQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "post",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "imaging",
            "storageKey": null,
            "args": null,
            "concreteType": "Imaging",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "images",
                "storageKey": "images(first:100)",
                "args": (v14/*: any*/),
                "concreteType": "ImageConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ImageEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Image",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "images",
                "args": (v14/*: any*/),
                "handle": "connection",
                "key": "List_images",
                "filters": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PostEditQuery",
    "id": null,
    "text": "query PostEditQuery(\n  $id: ID!\n) {\n  post(id: $id) {\n    id\n    url\n    title\n    body\n    summary\n    mainImage {\n      id\n    }\n    publishedAt\n    tags(first: 50) {\n      edges {\n        node {\n          id\n          slug\n          title\n        }\n      }\n    }\n    imaging {\n      id\n      images(first: 100) {\n        edges {\n          node {\n            id\n            smallImage: image(width: 200, height: 200) {\n              url\n            }\n            ...ImageThumbnail_image\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment ImageThumbnail_image on Image {\n  id\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "post",
            "imaging",
            "images"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4dc1211d6257511390a594a54aa336f1';
module.exports = node;
