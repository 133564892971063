/**
 * @flow
 * @relayHash d191dd2fdab9780ba93949f42d05e5ee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PopularPlants_viewer$ref = any;
export type PopularPlantsQueryVariables = {|
  count: number,
  cursor?: ?string,
|};
export type PopularPlantsQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +$fragmentRefs: PopularPlants_viewer$ref,
  |}
|};
export type PopularPlantsQuery = {|
  variables: PopularPlantsQueryVariables,
  response: PopularPlantsQueryResponse,
|};
*/


/*
query PopularPlantsQuery(
  $count: Int!
  $cursor: String
) {
  viewer {
    id
    ...PopularPlants_viewer_1G22uz
  }
}

fragment PopularPlants_viewer_1G22uz on Query {
  popularPlants: allLifeNode(first: $count, after: $cursor, orderBy: "-collection_count") {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        idInt
        title
        slug
        ...PlantItem_lifeNode
        images(first: 1) {
          edges {
            node {
              id
              bigImage: image(width: 440, height: 520) {
                url
              }
            }
          }
        }
        __typename
      }
      cursor
    }
  }
}

fragment PlantItem_lifeNode on LifeNode {
  id
  idInt
  title
  slug
  rankDisplay
  commonName {
    name
    id
  }
  images(first: 1) {
    edges {
      node {
        id
        bigImage: image(width: 440, height: 520) {
          url
        }
      }
    }
  }
  commonNames(first: 20) {
    edges {
      node {
        id
        name
        language
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "-collection_count"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PopularPlantsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "PopularPlants_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PopularPlantsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "popularPlants",
            "name": "allLifeNode",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "LifeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LifeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LifeNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "idInt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rankDisplay",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "commonName",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommonName",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v1/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "images",
                        "storageKey": "images(first:1)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          }
                        ],
                        "concreteType": "ImageConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ImageEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Image",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": "bigImage",
                                    "name": "image",
                                    "storageKey": "image(height:520,width:440)",
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "height",
                                        "value": 520
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "width",
                                        "value": 440
                                      }
                                    ],
                                    "concreteType": "File",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "url",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "commonNames",
                        "storageKey": "commonNames(first:20)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 20
                          }
                        ],
                        "concreteType": "CommonNameConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommonNameEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CommonName",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "language",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": "popularPlants",
            "name": "allLifeNode",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "PopularPlants_popularPlants",
            "filters": [
              "orderBy"
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PopularPlantsQuery",
    "id": null,
    "text": "query PopularPlantsQuery(\n  $count: Int!\n  $cursor: String\n) {\n  viewer {\n    id\n    ...PopularPlants_viewer_1G22uz\n  }\n}\n\nfragment PopularPlants_viewer_1G22uz on Query {\n  popularPlants: allLifeNode(first: $count, after: $cursor, orderBy: \"-collection_count\") {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        idInt\n        title\n        slug\n        ...PlantItem_lifeNode\n        images(first: 1) {\n          edges {\n            node {\n              id\n              bigImage: image(width: 440, height: 520) {\n                url\n              }\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment PlantItem_lifeNode on LifeNode {\n  id\n  idInt\n  title\n  slug\n  rankDisplay\n  commonName {\n    name\n    id\n  }\n  images(first: 1) {\n    edges {\n      node {\n        id\n        bigImage: image(width: 440, height: 520) {\n          url\n        }\n      }\n    }\n  }\n  commonNames(first: 20) {\n    edges {\n      node {\n        id\n        name\n        language\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '013d8648b942ab78c5b6fd1d3bbda9df';
module.exports = node;
