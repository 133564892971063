/**
 * @flow
 * @relayHash 12d05d5e2794193d04803e2c0ba2646a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PlantAddQueryVariables = {||};
export type PlantAddQueryResponse = {|
  +viewer: ?{|
    +id: string
  |},
  +ranks: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +edibilities: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +flowerTypes: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +flowerColors: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +growthHabits: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +growthRates: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +successions: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +threateneds: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
|};
export type PlantAddQuery = {|
  variables: PlantAddQueryVariables,
  response: PlantAddQueryResponse,
|};
*/


/*
query PlantAddQuery {
  viewer {
    id
  }
  ranks: __type(name: "Rank") {
    enumValues {
      name
      description
    }
  }
  edibilities: __type(name: "Edibility") {
    enumValues {
      name
      description
    }
  }
  flowerTypes: __type(name: "FlowerType") {
    enumValues {
      name
      description
    }
  }
  flowerColors: __type(name: "FlowerColor") {
    enumValues {
      name
      description
    }
  }
  growthHabits: __type(name: "GrowthHabit") {
    enumValues {
      name
      description
    }
  }
  growthRates: __type(name: "GrowthRate") {
    enumValues {
      name
      description
    }
  }
  successions: __type(name: "Succession") {
    enumValues {
      name
      description
    }
  }
  threateneds: __type(name: "Threatened") {
    enumValues {
      name
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "enumValues",
    "storageKey": null,
    "args": null,
    "concreteType": "__EnumValue",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "viewer",
    "storageKey": null,
    "args": null,
    "concreteType": "Query",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": "ranks",
    "name": "__type",
    "storageKey": "__type(name:\"Rank\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Rank"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "edibilities",
    "name": "__type",
    "storageKey": "__type(name:\"Edibility\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Edibility"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "flowerTypes",
    "name": "__type",
    "storageKey": "__type(name:\"FlowerType\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "FlowerType"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "flowerColors",
    "name": "__type",
    "storageKey": "__type(name:\"FlowerColor\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "FlowerColor"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "growthHabits",
    "name": "__type",
    "storageKey": "__type(name:\"GrowthHabit\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "GrowthHabit"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "growthRates",
    "name": "__type",
    "storageKey": "__type(name:\"GrowthRate\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "GrowthRate"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "successions",
    "name": "__type",
    "storageKey": "__type(name:\"Succession\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Succession"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "threateneds",
    "name": "__type",
    "storageKey": "__type(name:\"Threatened\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Threatened"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantAddQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantAddQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "PlantAddQuery",
    "id": null,
    "text": "query PlantAddQuery {\n  viewer {\n    id\n  }\n  ranks: __type(name: \"Rank\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  edibilities: __type(name: \"Edibility\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  flowerTypes: __type(name: \"FlowerType\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  flowerColors: __type(name: \"FlowerColor\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  growthHabits: __type(name: \"GrowthHabit\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  growthRates: __type(name: \"GrowthRate\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  successions: __type(name: \"Succession\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  threateneds: __type(name: \"Threatened\") {\n    enumValues {\n      name\n      description\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cfe3208c89a2b7c01ab175056fad1122';
module.exports = node;
