/**
 * @flow
 * @relayHash 8133debbb4891e3a05b690b6f2a53442
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Edibility = "BAD" | "EXCELLENT" | "FAIR" | "GOOD" | "POOR" | "SOME" | "%future added value";
export type FlowerColor = "BLACK" | "BLUE" | "BROWN" | "CREAM" | "GREEN" | "LIGHT_BLUE" | "LILAC" | "NUT_BROWN" | "ORANGE" | "PINK" | "PURPLE" | "RED" | "WHITE" | "WINE" | "YELLOW" | "%future added value";
export type FlowerType = "INFLORESCENCE" | "PSEUDANTHIUM" | "SOLITARY" | "%future added value";
export type GrowthHabit = "GRAMINOID" | "HERB" | "LICHENOUS" | "NONVASCULAR" | "SHRUB" | "SUBSHRUB" | "SUCCULENT" | "TREE" | "VINE" | "%future added value";
export type GrowthRate = "FAST" | "MODERATE" | "SLOW" | "%future added value";
export type Rank = "CLASS" | "FAMILY" | "GENUS" | "INFRASPECIES" | "KINGDOM" | "ORDER" | "PHYLUM" | "SPECIES" | "VARIETY" | "%future added value";
export type Succession = "CLIMAX" | "PRIMARY" | "SECONDARY" | "%future added value";
export type Threatened = "CONSERVATION_DEPENDENT" | "CRITICALLY_ENDANGERED" | "DATA_DEFICIENT" | "ENDANGERED" | "EXTINCT" | "EXTINCT_IN_THE_WILD" | "LEAST_CONCERN" | "NEAR_THREATENED" | "NOT_EVALUATED" | "VULNERABLE" | "%future added value";
export type PlantEditQueryVariables = {|
  plantID: number
|};
export type PlantEditQueryResponse = {|
  +plant: ?{|
    +id: string,
    +idInt: ?number,
    +parent: ?{|
      +id: string,
      +title: string,
    |},
    +title: string,
    +description: ?string,
    +edibility: ?Edibility,
    +rank: ?Rank,
    +document: ?{|
      +id: string
    |},
    +flowerTypes: ?$ReadOnlyArray<?FlowerType>,
    +flowerColors: ?$ReadOnlyArray<?FlowerColor>,
    +growthHabit: ?$ReadOnlyArray<?GrowthHabit>,
    +growthRate: ?$ReadOnlyArray<?GrowthRate>,
    +sun: ?{|
      +lower: ?any,
      +upper: ?any,
    |},
    +spread: ?{|
      +lower: ?any,
      +upper: ?any,
    |},
    +height: ?{|
      +lower: ?any,
      +upper: ?any,
    |},
    +succession: ?Succession,
    +threatened: ?Threatened,
  |},
  +ranks: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +edibilities: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +flowerTypes: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +flowerColors: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +growthHabits: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +growthRates: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +successions: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +threateneds: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
|};
export type PlantEditQuery = {|
  variables: PlantEditQueryVariables,
  response: PlantEditQueryResponse,
|};
*/


/*
query PlantEditQuery(
  $plantID: Int!
) {
  plant: lifeNodeByIntID(documentId: $plantID) {
    id
    idInt
    parent {
      id
      title
    }
    title
    description
    edibility
    rank
    document {
      id
    }
    flowerTypes
    flowerColors
    growthHabit
    growthRate
    sun {
      lower
      upper
    }
    spread {
      lower
      upper
    }
    height {
      lower
      upper
    }
    succession
    threatened
  }
  ranks: __type(name: "Rank") {
    enumValues {
      name
      description
    }
  }
  edibilities: __type(name: "Edibility") {
    enumValues {
      name
      description
    }
  }
  flowerTypes: __type(name: "FlowerType") {
    enumValues {
      name
      description
    }
  }
  flowerColors: __type(name: "FlowerColor") {
    enumValues {
      name
      description
    }
  }
  growthHabits: __type(name: "GrowthHabit") {
    enumValues {
      name
      description
    }
  }
  growthRates: __type(name: "GrowthRate") {
    enumValues {
      name
      description
    }
  }
  successions: __type(name: "Succession") {
    enumValues {
      name
      description
    }
  }
  threateneds: __type(name: "Threatened") {
    enumValues {
      name
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "plantID",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lower",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "upper",
    "args": null,
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "enumValues",
    "storageKey": null,
    "args": null,
    "concreteType": "__EnumValue",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  }
],
v6 = [
  {
    "kind": "LinkedField",
    "alias": "plant",
    "name": "lifeNodeByIntID",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "plantID"
      }
    ],
    "concreteType": "LifeNode",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "idInt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "parent",
        "storageKey": null,
        "args": null,
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      },
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "edibility",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rank",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "document",
        "storageKey": null,
        "args": null,
        "concreteType": "Document",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "flowerTypes",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "flowerColors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "growthHabit",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "growthRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sun",
        "storageKey": null,
        "args": null,
        "concreteType": "DecimalRangeObjectType",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "spread",
        "storageKey": null,
        "args": null,
        "concreteType": "DecimalRangeObjectType",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "height",
        "storageKey": null,
        "args": null,
        "concreteType": "DecimalRangeObjectType",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "succession",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "threatened",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": "ranks",
    "name": "__type",
    "storageKey": "__type(name:\"Rank\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Rank"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "edibilities",
    "name": "__type",
    "storageKey": "__type(name:\"Edibility\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Edibility"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "flowerTypes",
    "name": "__type",
    "storageKey": "__type(name:\"FlowerType\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "FlowerType"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "flowerColors",
    "name": "__type",
    "storageKey": "__type(name:\"FlowerColor\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "FlowerColor"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "growthHabits",
    "name": "__type",
    "storageKey": "__type(name:\"GrowthHabit\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "GrowthHabit"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "growthRates",
    "name": "__type",
    "storageKey": "__type(name:\"GrowthRate\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "GrowthRate"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "successions",
    "name": "__type",
    "storageKey": "__type(name:\"Succession\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Succession"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "threateneds",
    "name": "__type",
    "storageKey": "__type(name:\"Threatened\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "Threatened"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v5/*: any*/)
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantEditQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantEditQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "PlantEditQuery",
    "id": null,
    "text": "query PlantEditQuery(\n  $plantID: Int!\n) {\n  plant: lifeNodeByIntID(documentId: $plantID) {\n    id\n    idInt\n    parent {\n      id\n      title\n    }\n    title\n    description\n    edibility\n    rank\n    document {\n      id\n    }\n    flowerTypes\n    flowerColors\n    growthHabit\n    growthRate\n    sun {\n      lower\n      upper\n    }\n    spread {\n      lower\n      upper\n    }\n    height {\n      lower\n      upper\n    }\n    succession\n    threatened\n  }\n  ranks: __type(name: \"Rank\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  edibilities: __type(name: \"Edibility\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  flowerTypes: __type(name: \"FlowerType\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  flowerColors: __type(name: \"FlowerColor\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  growthHabits: __type(name: \"GrowthHabit\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  growthRates: __type(name: \"GrowthRate\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  successions: __type(name: \"Succession\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  threateneds: __type(name: \"Threatened\") {\n    enumValues {\n      name\n      description\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8ffa4b2f0fff9ab7d3727197f8ccfdb4';
module.exports = node;
