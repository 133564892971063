/**
 * @flow
 * @relayHash 4b741eb3610648f1667af5364bee5d41
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaxoClimb_lifeNode$ref = any;
export type TaxoClimbQueryVariables = {|
  count: number,
  cursor?: ?string,
  plantID: number,
|};
export type TaxoClimbQueryResponse = {|
  +lifeNodeByIntID: ?{|
    +$fragmentRefs: TaxoClimb_lifeNode$ref
  |}
|};
export type TaxoClimbQuery = {|
  variables: TaxoClimbQueryVariables,
  response: TaxoClimbQueryResponse,
|};
*/


/*
query TaxoClimbQuery(
  $count: Int!
  $cursor: String
  $plantID: Int!
) {
  lifeNodeByIntID(documentId: $plantID) {
    ...TaxoClimb_lifeNode_1G22uz
    id
  }
}

fragment TaxoClimb_lifeNode_1G22uz on LifeNode {
  children(first: $count, after: $cursor) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        rankDisplay
        ...PlantLink_plant
        __typename
      }
      cursor
    }
  }
}

fragment PlantLink_plant on LifeNode {
  id
  idInt
  title
  slug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "plantID",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "documentId",
    "variableName": "plantID"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TaxoClimbQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lifeNodeByIntID",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TaxoClimb_lifeNode",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TaxoClimbQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lifeNodeByIntID",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "children",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "LifeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LifeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LifeNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rankDisplay",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "idInt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "children",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "ListLifeNode_children",
            "filters": null
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TaxoClimbQuery",
    "id": null,
    "text": "query TaxoClimbQuery(\n  $count: Int!\n  $cursor: String\n  $plantID: Int!\n) {\n  lifeNodeByIntID(documentId: $plantID) {\n    ...TaxoClimb_lifeNode_1G22uz\n    id\n  }\n}\n\nfragment TaxoClimb_lifeNode_1G22uz on LifeNode {\n  children(first: $count, after: $cursor) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        rankDisplay\n        ...PlantLink_plant\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment PlantLink_plant on LifeNode {\n  id\n  idInt\n  title\n  slug\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '10ccac529f5556cdc688d22c13c1d048';
module.exports = node;
