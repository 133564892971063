/**
 * @flow
 * @relayHash a0241d464db89f4dfa96303dac8163a5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ImageThumbnail_image$ref = any;
export type ImageThumbnailQueryVariables = {|
  id: string,
  isOpen: boolean,
|};
export type ImageThumbnailQueryResponse = {|
  +image: ?{|
    +id: string,
    +$fragmentRefs: ImageThumbnail_image$ref,
  |}
|};
export type ImageThumbnailQuery = {|
  variables: ImageThumbnailQueryVariables,
  response: ImageThumbnailQueryResponse,
|};
*/


/*
query ImageThumbnailQuery(
  $id: ID!
  $isOpen: Boolean!
) {
  image(id: $id) {
    id
    ...ImageThumbnail_image_27Rn4z
  }
}

fragment ImageThumbnail_image_27Rn4z on Image {
  id
  bigImage: image(width: 1200, height: 825) @include(if: $isOpen) {
    url
  }
  myPerms @include(if: $isOpen)
  revisionCreated @include(if: $isOpen) {
    createdAt
    author {
      id
      username
      avatar(width: 40, height: 40) {
        url
      }
      ...ProfileLink_user
    }
    id
  }
  commenting @include(if: $isOpen) {
    id
    count
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isOpen",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ImageThumbnailQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "image",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Image",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "ImageThumbnail_image",
            "args": [
              {
                "kind": "Variable",
                "name": "isOpen",
                "variableName": "isOpen"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ImageThumbnailQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "image",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Image",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "isOpen",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "bigImage",
                "name": "image",
                "storageKey": "image(height:825,width:1200)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "height",
                    "value": 825
                  },
                  {
                    "kind": "Literal",
                    "name": "width",
                    "value": 1200
                  }
                ],
                "concreteType": "File",
                "plural": false,
                "selections": (v3/*: any*/)
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "myPerms",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "revisionCreated",
                "storageKey": null,
                "args": null,
                "concreteType": "Revision",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "avatar",
                        "storageKey": "avatar(height:40,width:40)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "height",
                            "value": 40
                          },
                          {
                            "kind": "Literal",
                            "name": "width",
                            "value": 40
                          }
                        ],
                        "concreteType": "File",
                        "plural": false,
                        "selections": (v3/*: any*/)
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reputation",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "commenting",
                "storageKey": null,
                "args": null,
                "concreteType": "Commenting",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "count",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ImageThumbnailQuery",
    "id": null,
    "text": "query ImageThumbnailQuery(\n  $id: ID!\n  $isOpen: Boolean!\n) {\n  image(id: $id) {\n    id\n    ...ImageThumbnail_image_27Rn4z\n  }\n}\n\nfragment ImageThumbnail_image_27Rn4z on Image {\n  id\n  bigImage: image(width: 1200, height: 825) @include(if: $isOpen) {\n    url\n  }\n  myPerms @include(if: $isOpen)\n  revisionCreated @include(if: $isOpen) {\n    createdAt\n    author {\n      id\n      username\n      avatar(width: 40, height: 40) {\n        url\n      }\n      ...ProfileLink_user\n    }\n    id\n  }\n  commenting @include(if: $isOpen) {\n    id\n    count\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7509f311b5b58c51dc97f97e810d3535';
module.exports = node;
