/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ImageThumbnail_image$ref = any;
type PlantLink_plant$ref = any;
type ProfileLink_user$ref = any;
type SuggestionsList_occurrence$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type WhatIsThis_occurrence$ref: FragmentReference;
declare export opaque type WhatIsThis_occurrence$fragmentType: WhatIsThis_occurrence$ref;
export type WhatIsThis_occurrence = {|
  +id: string,
  +where: ?string,
  +when: ?string,
  +notes: ?string,
  +myPerms: ?$ReadOnlyArray<?string>,
  +identity: ?{|
    +id: string,
    +$fragmentRefs: PlantLink_plant$ref,
  |},
  +images: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +smallImage: ?{|
          +url: string
        |},
        +$fragmentRefs: ImageThumbnail_image$ref,
      |}
    |}>
  |},
  +revisionCreated: ?{|
    +createdAt: any,
    +author: ?{|
      +avatar: ?{|
        +url: string
      |},
      +username: string,
      +$fragmentRefs: ProfileLink_user$ref,
    |},
  |},
  +$fragmentRefs: SuggestionsList_occurrence$ref,
  +$refType: WhatIsThis_occurrence$ref,
|};
export type WhatIsThis_occurrence$data = WhatIsThis_occurrence;
export type WhatIsThis_occurrence$key = {
  +$data?: WhatIsThis_occurrence$data,
  +$fragmentRefs: WhatIsThis_occurrence$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "WhatIsThis_occurrence",
  "type": "Occurrence",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "where",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "when",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "myPerms",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "identity",
      "storageKey": null,
      "args": null,
      "concreteType": "LifeNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "FragmentSpread",
          "name": "PlantLink_plant",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "images",
      "storageKey": "images(first:20)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        }
      ],
      "concreteType": "ImageConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ImageEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Image",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": "smallImage",
                  "name": "image",
                  "storageKey": "image(height:645,width:860)",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "height",
                      "value": 645
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 860
                    }
                  ],
                  "concreteType": "File",
                  "plural": false,
                  "selections": (v1/*: any*/)
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ImageThumbnail_image",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "revisionCreated",
      "storageKey": null,
      "args": null,
      "concreteType": "Revision",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "author",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "avatar",
              "storageKey": "avatar(height:40,width:40)",
              "args": [
                {
                  "kind": "Literal",
                  "name": "height",
                  "value": 40
                },
                {
                  "kind": "Literal",
                  "name": "width",
                  "value": 40
                }
              ],
              "concreteType": "File",
              "plural": false,
              "selections": (v1/*: any*/)
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "username",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "ProfileLink_user",
              "args": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "SuggestionsList_occurrence",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3a96796e3189e74e5cfd149654d5bf3';
module.exports = node;
