/**
 * @flow
 * @relayHash f3bf16a105ff0d5039a7ab8af586c69f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WishItemDeleteInput = {|
  id: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type WishItemDeleteMutationVariables = {|
  input: WishItemDeleteInput
|};
export type WishItemDeleteMutationResponse = {|
  +wishItemDelete: ?{|
    +deletedId: string,
    +lifeNode: ?{|
      +id: string,
      +wishList: ?{|
        +totalCount: ?number
      |},
      +myWishItem: ?{|
        +id: string
      |},
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type WishItemDeleteMutation = {|
  variables: WishItemDeleteMutationVariables,
  response: WishItemDeleteMutationResponse,
|};
*/


/*
mutation WishItemDeleteMutation(
  $input: WishItemDeleteInput!
) {
  wishItemDelete(input: $input) {
    deletedId
    lifeNode {
      id
      wishList {
        totalCount
      }
      myWishItem {
        id
      }
    }
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "WishItemDeleteInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "wishItemDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "WishItemDeletePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lifeNode",
        "storageKey": null,
        "args": null,
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "wishList",
            "storageKey": null,
            "args": null,
            "concreteType": "WishItemConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "myWishItem",
            "storageKey": null,
            "args": null,
            "concreteType": "WishItem",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WishItemDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "WishItemDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "WishItemDeleteMutation",
    "id": null,
    "text": "mutation WishItemDeleteMutation(\n  $input: WishItemDeleteInput!\n) {\n  wishItemDelete(input: $input) {\n    deletedId\n    lifeNode {\n      id\n      wishList {\n        totalCount\n      }\n      myWishItem {\n        id\n      }\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12698ccbd6ce76e974104a8621259dcc';
module.exports = node;
