/**
 * @flow
 * @relayHash ad8e0d3e0f9f10c112f34e96e4b832fb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SuggestionIDDeleteInput = {|
  id: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type SuggestionDeleteMutationVariables = {|
  input: SuggestionIDDeleteInput
|};
export type SuggestionDeleteMutationResponse = {|
  +suggestionIDDelete: ?{|
    +suggestionDeletedID: string,
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type SuggestionDeleteMutation = {|
  variables: SuggestionDeleteMutationVariables,
  response: SuggestionDeleteMutationResponse,
|};
*/


/*
mutation SuggestionDeleteMutation(
  $input: SuggestionIDDeleteInput!
) {
  suggestionIDDelete(input: $input) {
    suggestionDeletedID
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SuggestionIDDeleteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "suggestionIDDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SuggestionIDDeletePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "suggestionDeletedID",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SuggestionDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SuggestionDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SuggestionDeleteMutation",
    "id": null,
    "text": "mutation SuggestionDeleteMutation(\n  $input: SuggestionIDDeleteInput!\n) {\n  suggestionIDDelete(input: $input) {\n    suggestionDeletedID\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '52eba3ebda6c6a1b89e06b751bbaf72c';
module.exports = node;
