/**
 * @flow
 * @relayHash c908d30585ae2af53d2525df66a5605e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PlantSelectField_viewer$ref = any;
export type PlantSelectFieldQueryVariables = {|
  count: number,
  search: string,
  exclude?: ?number,
|};
export type PlantSelectFieldQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +$fragmentRefs: PlantSelectField_viewer$ref,
  |}
|};
export type PlantSelectFieldQuery = {|
  variables: PlantSelectFieldQueryVariables,
  response: PlantSelectFieldQueryResponse,
|};
*/


/*
query PlantSelectFieldQuery(
  $count: Int!
  $search: String!
  $exclude: Int
) {
  viewer {
    id
    ...PlantSelectField_viewer_4vHW4V
  }
}

fragment PlantSelectField_viewer_4vHW4V on Query {
  id
  allLifeNode(first: $count, search: $search, exclude: $exclude) {
    edges {
      node {
        id
        title
        rankDisplay
        images(first: 1) {
          edges {
            node {
              id
              mainImage: image(width: 200, height: 200) {
                url
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "exclude",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "exclude",
  "variableName": "exclude"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantSelectFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "PlantSelectField_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantSelectFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allLifeNode",
            "storageKey": null,
            "args": [
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "count"
              },
              (v3/*: any*/)
            ],
            "concreteType": "LifeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LifeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LifeNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rankDisplay",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "images",
                        "storageKey": "images(first:1)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          }
                        ],
                        "concreteType": "ImageConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ImageEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Image",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": "mainImage",
                                    "name": "image",
                                    "storageKey": "image(height:200,width:200)",
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "height",
                                        "value": 200
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "width",
                                        "value": 200
                                      }
                                    ],
                                    "concreteType": "File",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "url",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PlantSelectFieldQuery",
    "id": null,
    "text": "query PlantSelectFieldQuery(\n  $count: Int!\n  $search: String!\n  $exclude: Int\n) {\n  viewer {\n    id\n    ...PlantSelectField_viewer_4vHW4V\n  }\n}\n\nfragment PlantSelectField_viewer_4vHW4V on Query {\n  id\n  allLifeNode(first: $count, search: $search, exclude: $exclude) {\n    edges {\n      node {\n        id\n        title\n        rankDisplay\n        images(first: 1) {\n          edges {\n            node {\n              id\n              mainImage: image(width: 200, height: 200) {\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ed7715ee553d765136d98a100a14716';
module.exports = node;
