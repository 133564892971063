/**
 * @flow
 * @relayHash 4d97034617f1c767c94f79ef5a2e0998
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ImageThumbnail_image$ref = any;
export type PlantPhotosQueryVariables = {|
  plantID: number
|};
export type PlantPhotosQueryResponse = {|
  +plant: ?{|
    +id: string,
    +idInt: ?number,
    +title: string,
    +images: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +smallImage: ?{|
            +url: string
          |},
          +$fragmentRefs: ImageThumbnail_image$ref,
        |}
      |}>
    |},
  |}
|};
export type PlantPhotosQuery = {|
  variables: PlantPhotosQueryVariables,
  response: PlantPhotosQueryResponse,
|};
*/


/*
query PlantPhotosQuery(
  $plantID: Int!
) {
  plant: lifeNodeByIntID(documentId: $plantID) {
    id
    idInt
    title
    images(first: 20) {
      edges {
        node {
          id
          smallImage: image(width: 200, height: 200) {
            url
          }
          ...ImageThumbnail_image
        }
      }
    }
  }
}

fragment ImageThumbnail_image on Image {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "plantID",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "documentId",
    "variableName": "plantID"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idInt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v6 = {
  "kind": "LinkedField",
  "alias": "smallImage",
  "name": "image",
  "storageKey": "image(height:200,width:200)",
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 200
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 200
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantPhotosQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "plant",
        "name": "lifeNodeByIntID",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "images",
            "storageKey": "images(first:20)",
            "args": (v5/*: any*/),
            "concreteType": "ImageConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ImageEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "ImageThumbnail_image",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantPhotosQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "plant",
        "name": "lifeNodeByIntID",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "images",
            "storageKey": "images(first:20)",
            "args": (v5/*: any*/),
            "concreteType": "ImageConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ImageEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PlantPhotosQuery",
    "id": null,
    "text": "query PlantPhotosQuery(\n  $plantID: Int!\n) {\n  plant: lifeNodeByIntID(documentId: $plantID) {\n    id\n    idInt\n    title\n    images(first: 20) {\n      edges {\n        node {\n          id\n          smallImage: image(width: 200, height: 200) {\n            url\n          }\n          ...ImageThumbnail_image\n        }\n      }\n    }\n  }\n}\n\nfragment ImageThumbnail_image on Image {\n  id\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b9e84f1d8d0bdb903ae8c0954264573e';
module.exports = node;
