/**
 * @flow
 * @relayHash 6c2f15b62aa528060e1fb30124fae400
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CommentDeleteInput = {|
  id: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type CommentDeleteMutationVariables = {|
  input: CommentDeleteInput
|};
export type CommentDeleteMutationResponse = {|
  +commentDelete: ?{|
    +commentDeletedID: string,
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type CommentDeleteMutation = {|
  variables: CommentDeleteMutationVariables,
  response: CommentDeleteMutationResponse,
|};
*/


/*
mutation CommentDeleteMutation(
  $input: CommentDeleteInput!
) {
  commentDelete(input: $input) {
    commentDeletedID
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CommentDeleteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "commentDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CommentDeletePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "commentDeletedID",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CommentDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CommentDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CommentDeleteMutation",
    "id": null,
    "text": "mutation CommentDeleteMutation(\n  $input: CommentDeleteInput!\n) {\n  commentDelete(input: $input) {\n    commentDeletedID\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0396d73a1086822409f4e49beaf505f';
module.exports = node;
