/**
 * @flow
 * @relayHash ba6393a9b02864249622613de98b8c67
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProfileEditQueryVariables = {||};
export type ProfileEditQueryResponse = {|
  +me: ?{|
    +id: string,
    +username: string,
    +firstName: string,
    +email: string,
    +avatar: ?{|
      +url: string
    |},
    +isAuthenticated: ?boolean,
    +myPerms: ?$ReadOnlyArray<?string>,
  |}
|};
export type ProfileEditQuery = {|
  variables: ProfileEditQueryVariables,
  response: ProfileEditQueryResponse,
|};
*/


/*
query ProfileEditQuery {
  me {
    id
    username
    firstName
    email
    avatar(width: 40, height: 40) {
      url
    }
    isAuthenticated
    myPerms
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "avatar",
        "storageKey": "avatar(height:40,width:40)",
        "args": [
          {
            "kind": "Literal",
            "name": "height",
            "value": 40
          },
          {
            "kind": "Literal",
            "name": "width",
            "value": 40
          }
        ],
        "concreteType": "File",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isAuthenticated",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "myPerms",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProfileEditQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ProfileEditQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ProfileEditQuery",
    "id": null,
    "text": "query ProfileEditQuery {\n  me {\n    id\n    username\n    firstName\n    email\n    avatar(width: 40, height: 40) {\n      url\n    }\n    isAuthenticated\n    myPerms\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03e075ca2aeb1579b642bce591859765';
module.exports = node;
