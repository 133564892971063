/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PlantItem_lifeNode$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MostDesiredPlants_viewer$ref: FragmentReference;
declare export opaque type MostDesiredPlants_viewer$fragmentType: MostDesiredPlants_viewer$ref;
export type MostDesiredPlants_viewer = {|
  +mostDesiredPlants: ?{|
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +idInt: ?number,
        +title: string,
        +slug: ?string,
        +images: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +bigImage: ?{|
                +url: string
              |},
            |}
          |}>
        |},
        +$fragmentRefs: PlantItem_lifeNode$ref,
      |}
    |}>,
  |},
  +$refType: MostDesiredPlants_viewer$ref,
|};
export type MostDesiredPlants_viewer$data = MostDesiredPlants_viewer;
export type MostDesiredPlants_viewer$key = {
  +$data?: MostDesiredPlants_viewer$data,
  +$fragmentRefs: MostDesiredPlants_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MostDesiredPlants_viewer",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "mostDesiredPlants"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 30
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "mostDesiredPlants",
      "name": "__MostDesiredPlants_mostDesiredPlants_connection",
      "storageKey": "__MostDesiredPlants_mostDesiredPlants_connection(orderBy:\"-wish_count\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-wish_count"
        }
      ],
      "concreteType": "LifeNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "LifeNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "LifeNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "idInt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "slug",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "images",
                  "storageKey": "images(first:1)",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    }
                  ],
                  "concreteType": "ImageConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ImageEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Image",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": "bigImage",
                              "name": "image",
                              "storageKey": "image(height:520,width:440)",
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "height",
                                  "value": 520
                                },
                                {
                                  "kind": "Literal",
                                  "name": "width",
                                  "value": 440
                                }
                              ],
                              "concreteType": "File",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "url",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "PlantItem_lifeNode",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee850d723feec594913a5e79d371c936';
module.exports = node;
