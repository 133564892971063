/**
 * @flow
 * @relayHash d395c09a479b78e5f27bc7d0da669c02
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PlantList_viewer$ref = any;
export type Edibility = "BAD" | "EXCELLENT" | "FAIR" | "GOOD" | "POOR" | "SOME" | "%future added value";
export type Rank = "CLASS" | "FAMILY" | "GENUS" | "INFRASPECIES" | "KINGDOM" | "ORDER" | "PHYLUM" | "SPECIES" | "VARIETY" | "%future added value";
export type HomePlantsFilterQueryVariables = {|
  search?: ?string,
  edibles?: ?boolean,
  edibility?: ?$ReadOnlyArray<?Edibility>,
  rank?: ?$ReadOnlyArray<?Rank>,
  flowerColors?: ?$ReadOnlyArray<?string>,
  leafType?: ?$ReadOnlyArray<?string>,
  phyllotaxy?: ?$ReadOnlyArray<?string>,
  flowerTypes?: ?$ReadOnlyArray<?string>,
  fruitType?: ?$ReadOnlyArray<?string>,
  growthHabit?: ?$ReadOnlyArray<?string>,
  threatened?: ?$ReadOnlyArray<?string>,
|};
export type HomePlantsFilterQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +$fragmentRefs: PlantList_viewer$ref,
  |}
|};
export type HomePlantsFilterQuery = {|
  variables: HomePlantsFilterQueryVariables,
  response: HomePlantsFilterQueryResponse,
|};
*/


/*
query HomePlantsFilterQuery(
  $search: String
  $edibles: Boolean
  $edibility: [Edibility]
  $rank: [Rank]
  $flowerColors: [String]
  $leafType: [String]
  $phyllotaxy: [String]
  $flowerTypes: [String]
  $fruitType: [String]
  $growthHabit: [String]
  $threatened: [String]
) {
  viewer {
    id
    ...PlantList_viewer_16K6ai
  }
}

fragment PlantList_viewer_16K6ai on Query {
  allLifeNode(first: 30, search: $search, edibles: $edibles, edibility: $edibility, rank: $rank, flowerColors: $flowerColors, leafType: $leafType, phyllotaxy: $phyllotaxy, flowerTypes: $flowerTypes, fruitType: $fruitType, growthHabit: $growthHabit, threatened: $threatened) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        ...PlantItem_lifeNode
        __typename
      }
      cursor
    }
  }
}

fragment PlantItem_lifeNode on LifeNode {
  id
  idInt
  title
  slug
  rankDisplay
  commonName {
    name
    id
  }
  images(first: 1) {
    edges {
      node {
        id
        bigImage: image(width: 440, height: 520) {
          url
        }
      }
    }
  }
  commonNames(first: 20) {
    edges {
      node {
        id
        name
        language
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "edibles",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "edibility",
    "type": "[Edibility]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "rank",
    "type": "[Rank]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "flowerColors",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "leafType",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "phyllotaxy",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "flowerTypes",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "fruitType",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "growthHabit",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "threatened",
    "type": "[String]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "edibility",
  "variableName": "edibility"
},
v3 = {
  "kind": "Variable",
  "name": "edibles",
  "variableName": "edibles"
},
v4 = {
  "kind": "Variable",
  "name": "flowerColors",
  "variableName": "flowerColors"
},
v5 = {
  "kind": "Variable",
  "name": "flowerTypes",
  "variableName": "flowerTypes"
},
v6 = {
  "kind": "Variable",
  "name": "fruitType",
  "variableName": "fruitType"
},
v7 = {
  "kind": "Variable",
  "name": "growthHabit",
  "variableName": "growthHabit"
},
v8 = {
  "kind": "Variable",
  "name": "leafType",
  "variableName": "leafType"
},
v9 = {
  "kind": "Variable",
  "name": "phyllotaxy",
  "variableName": "phyllotaxy"
},
v10 = {
  "kind": "Variable",
  "name": "rank",
  "variableName": "rank"
},
v11 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v12 = {
  "kind": "Variable",
  "name": "threatened",
  "variableName": "threatened"
},
v13 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HomePlantsFilterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "PlantList_viewer",
            "args": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomePlantsFilterQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allLifeNode",
            "storageKey": null,
            "args": (v13/*: any*/),
            "concreteType": "LifeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LifeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LifeNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "idInt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rankDisplay",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "commonName",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommonName",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v1/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "images",
                        "storageKey": "images(first:1)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          }
                        ],
                        "concreteType": "ImageConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ImageEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Image",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": "bigImage",
                                    "name": "image",
                                    "storageKey": "image(height:520,width:440)",
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "height",
                                        "value": 520
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "width",
                                        "value": 440
                                      }
                                    ],
                                    "concreteType": "File",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "url",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "commonNames",
                        "storageKey": "commonNames(first:20)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 20
                          }
                        ],
                        "concreteType": "CommonNameConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommonNameEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CommonName",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "language",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "allLifeNode",
            "args": (v13/*: any*/),
            "handle": "connection",
            "key": "ListLifeNode_allLifeNode",
            "filters": [
              "search",
              "edibles",
              "edibility",
              "rank",
              "flowerColors",
              "leafType",
              "phyllotaxy",
              "flowerTypes",
              "fruitType",
              "growthHabit",
              "threatened"
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "HomePlantsFilterQuery",
    "id": null,
    "text": "query HomePlantsFilterQuery(\n  $search: String\n  $edibles: Boolean\n  $edibility: [Edibility]\n  $rank: [Rank]\n  $flowerColors: [String]\n  $leafType: [String]\n  $phyllotaxy: [String]\n  $flowerTypes: [String]\n  $fruitType: [String]\n  $growthHabit: [String]\n  $threatened: [String]\n) {\n  viewer {\n    id\n    ...PlantList_viewer_16K6ai\n  }\n}\n\nfragment PlantList_viewer_16K6ai on Query {\n  allLifeNode(first: 30, search: $search, edibles: $edibles, edibility: $edibility, rank: $rank, flowerColors: $flowerColors, leafType: $leafType, phyllotaxy: $phyllotaxy, flowerTypes: $flowerTypes, fruitType: $fruitType, growthHabit: $growthHabit, threatened: $threatened) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...PlantItem_lifeNode\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment PlantItem_lifeNode on LifeNode {\n  id\n  idInt\n  title\n  slug\n  rankDisplay\n  commonName {\n    name\n    id\n  }\n  images(first: 1) {\n    edges {\n      node {\n        id\n        bigImage: image(width: 440, height: 520) {\n          url\n        }\n      }\n    }\n  }\n  commonNames(first: 20) {\n    edges {\n      node {\n        id\n        name\n        language\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '327f9a08518819bf4e66678b9429a42b';
module.exports = node;
