/**
 * @flow
 * @relayHash 8410f48446615556b37da93fde6176e1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProfileLink_user$ref = any;
export type RevisionType = "CREATE" | "DELETE" | "UPDATE" | "%future added value";
export type RevisionListQueryVariables = {|
  nodeID: string
|};
export type RevisionListQueryResponse = {|
  +node: ?{|
    +id: string,
    +__typename: string,
    +idInt?: ?number,
    +revisions?: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +idInt: ?number,
          +index: number,
          +type: ?RevisionType,
          +typeDisplay: ?string,
          +isTip: ?boolean,
          +author: ?{|
            +$fragmentRefs: ProfileLink_user$ref
          |},
          +message: ?string,
          +createdAt: any,
        |}
      |}>
    |},
    +title?: string,
    +url?: string,
    +slug?: ?string,
  |}
|};
export type RevisionListQuery = {|
  variables: RevisionListQueryVariables,
  response: RevisionListQueryResponse,
|};
*/


/*
query RevisionListQuery(
  $nodeID: ID!
) {
  node(id: $nodeID) {
    id
    __typename
    ... on DocumentNode {
      idInt
      revisions {
        edges {
          node {
            id
            idInt
            index
            type
            typeDisplay
            isTip
            author {
              ...ProfileLink_user
              id
            }
            message
            createdAt
          }
        }
      }
    }
    ... on Post {
      title
      url
    }
    ... on Page {
      title
      url
    }
    ... on LifeNode {
      idInt
      title
      slug
    }
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "nodeID",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "nodeID"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idInt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "index",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "typeDisplay",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isTip",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v13 = [
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "kind": "InlineFragment",
  "type": "Post",
  "selections": (v13/*: any*/)
},
v15 = {
  "kind": "InlineFragment",
  "type": "Page",
  "selections": (v13/*: any*/)
},
v16 = {
  "kind": "InlineFragment",
  "type": "LifeNode",
  "selections": [
    (v11/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RevisionListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "revisions",
            "storageKey": null,
            "args": null,
            "concreteType": "RevisionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "RevisionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Revision",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "ProfileLink_user",
                            "args": null
                          }
                        ]
                      },
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RevisionListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "revisions",
            "storageKey": null,
            "args": null,
            "concreteType": "RevisionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "RevisionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Revision",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "reputation",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "avatar",
                            "storageKey": "avatar(height:40,width:40)",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "height",
                                "value": 40
                              },
                              {
                                "kind": "Literal",
                                "name": "width",
                                "value": 40
                              }
                            ],
                            "concreteType": "File",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RevisionListQuery",
    "id": null,
    "text": "query RevisionListQuery(\n  $nodeID: ID!\n) {\n  node(id: $nodeID) {\n    id\n    __typename\n    ... on DocumentNode {\n      idInt\n      revisions {\n        edges {\n          node {\n            id\n            idInt\n            index\n            type\n            typeDisplay\n            isTip\n            author {\n              ...ProfileLink_user\n              id\n            }\n            message\n            createdAt\n          }\n        }\n      }\n    }\n    ... on Post {\n      title\n      url\n    }\n    ... on Page {\n      title\n      url\n    }\n    ... on LifeNode {\n      idInt\n      title\n      slug\n    }\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7863538ff5e05be31f79e3dbb9f1d5ac';
module.exports = node;
