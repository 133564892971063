/**
 * @flow
 * @relayHash 94ef2ac6efbf63c2ed210e4163a4ec70
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CollectionItemToggle_plant$ref = any;
type ImageThumbnail_image$ref = any;
type RankDisplay_plant$ref = any;
type RevisionBox_document$ref = any;
type WishItemToggle_plant$ref = any;
export type Edibility = "BAD" | "EXCELLENT" | "FAIR" | "GOOD" | "POOR" | "SOME" | "%future added value";
export type PlantQueryVariables = {|
  plantID: number
|};
export type PlantQueryResponse = {|
  +plant: ?{|
    +id: string,
    +idInt: ?number,
    +title: string,
    +commonName: ?{|
      +name: string
    |},
    +slug: ?string,
    +edibility: ?Edibility,
    +edibilityDisplay: ?string,
    +mainImage: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +smallImage: ?{|
            +url: string
          |},
          +$fragmentRefs: ImageThumbnail_image$ref,
        |}
      |}>
    |},
    +document: ?{|
      +$fragmentRefs: RevisionBox_document$ref
    |},
    +myPerms: ?$ReadOnlyArray<?string>,
    +collectionList: ?{|
      +totalCount: ?number
    |},
    +wishList: ?{|
      +totalCount: ?number
    |},
    +$fragmentRefs: RankDisplay_plant$ref & CollectionItemToggle_plant$ref & WishItemToggle_plant$ref,
  |}
|};
export type PlantQuery = {|
  variables: PlantQueryVariables,
  response: PlantQueryResponse,
|};
*/


/*
query PlantQuery(
  $plantID: Int!
) {
  plant: lifeNodeByIntID(documentId: $plantID) {
    id
    idInt
    title
    commonName {
      name
      id
    }
    slug
    edibility
    edibilityDisplay
    ...RankDisplay_plant
    mainImage: images(first: 1) {
      edges {
        node {
          id
          smallImage: image(width: 225, height: 300) {
            url
          }
          ...ImageThumbnail_image
        }
      }
    }
    document {
      ...RevisionBox_document
      id
    }
    myPerms
    collectionList {
      totalCount
    }
    wishList {
      totalCount
    }
    ...CollectionItemToggle_plant
    ...WishItemToggle_plant
  }
}

fragment RankDisplay_plant on LifeNode {
  title
  rankDisplay
  parents {
    id
    idInt
    title
    slug
    rankDisplay
    ...PlantLink_plant
  }
}

fragment ImageThumbnail_image on Image {
  id
}

fragment RevisionBox_document on Document {
  id
  revisionsCount
  revisionTip {
    id
    createdAt
    author {
      username
      ...ProfileLink_user
      id
    }
  }
  revisionCreated {
    createdAt
    author {
      username
      ...ProfileLink_user
      id
    }
    id
  }
}

fragment CollectionItemToggle_plant on LifeNode {
  id
  title
  myCollectionItem {
    id
  }
}

fragment WishItemToggle_plant on LifeNode {
  id
  title
  myWishItem {
    id
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}

fragment PlantLink_plant on LifeNode {
  id
  idInt
  title
  slug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "plantID",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "documentId",
    "variableName": "plantID"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idInt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "edibility",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "edibilityDisplay",
  "args": null,
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v10 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
],
v11 = {
  "kind": "LinkedField",
  "alias": "smallImage",
  "name": "image",
  "storageKey": "image(height:300,width:225)",
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 300
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 225
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": (v10/*: any*/)
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "myPerms",
  "args": null,
  "storageKey": null
},
v13 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "collectionList",
  "storageKey": null,
  "args": null,
  "concreteType": "CollectionItemConnection",
  "plural": false,
  "selections": (v13/*: any*/)
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "wishList",
  "storageKey": null,
  "args": null,
  "concreteType": "WishItemConnection",
  "plural": false,
  "selections": (v13/*: any*/)
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rankDisplay",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reputation",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "avatar",
      "storageKey": "avatar(height:40,width:40)",
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 40
        },
        {
          "kind": "Literal",
          "name": "width",
          "value": 40
        }
      ],
      "concreteType": "File",
      "plural": false,
      "selections": (v10/*: any*/)
    }
  ]
},
v19 = [
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "plant",
        "name": "lifeNodeByIntID",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commonName",
            "storageKey": null,
            "args": null,
            "concreteType": "CommonName",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ]
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "mainImage",
            "name": "images",
            "storageKey": "images(first:1)",
            "args": (v9/*: any*/),
            "concreteType": "ImageConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ImageEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "ImageThumbnail_image",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "Document",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "RevisionBox_document",
                "args": null
              }
            ]
          },
          (v12/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "RankDisplay_plant",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "CollectionItemToggle_plant",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "WishItemToggle_plant",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "plant",
        "name": "lifeNodeByIntID",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commonName",
            "storageKey": null,
            "args": null,
            "concreteType": "CommonName",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v16/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "parents",
            "storageKey": null,
            "args": null,
            "concreteType": "LifeNode",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v16/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "mainImage",
            "name": "images",
            "storageKey": "images(first:1)",
            "args": (v9/*: any*/),
            "concreteType": "ImageConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ImageEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "Document",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "revisionsCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "revisionTip",
                "storageKey": null,
                "args": null,
                "concreteType": "Revision",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "revisionCreated",
                "storageKey": null,
                "args": null,
                "concreteType": "Revision",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          (v12/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "myCollectionItem",
            "storageKey": null,
            "args": null,
            "concreteType": "CollectionItem",
            "plural": false,
            "selections": (v19/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "myWishItem",
            "storageKey": null,
            "args": null,
            "concreteType": "WishItem",
            "plural": false,
            "selections": (v19/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PlantQuery",
    "id": null,
    "text": "query PlantQuery(\n  $plantID: Int!\n) {\n  plant: lifeNodeByIntID(documentId: $plantID) {\n    id\n    idInt\n    title\n    commonName {\n      name\n      id\n    }\n    slug\n    edibility\n    edibilityDisplay\n    ...RankDisplay_plant\n    mainImage: images(first: 1) {\n      edges {\n        node {\n          id\n          smallImage: image(width: 225, height: 300) {\n            url\n          }\n          ...ImageThumbnail_image\n        }\n      }\n    }\n    document {\n      ...RevisionBox_document\n      id\n    }\n    myPerms\n    collectionList {\n      totalCount\n    }\n    wishList {\n      totalCount\n    }\n    ...CollectionItemToggle_plant\n    ...WishItemToggle_plant\n  }\n}\n\nfragment RankDisplay_plant on LifeNode {\n  title\n  rankDisplay\n  parents {\n    id\n    idInt\n    title\n    slug\n    rankDisplay\n    ...PlantLink_plant\n  }\n}\n\nfragment ImageThumbnail_image on Image {\n  id\n}\n\nfragment RevisionBox_document on Document {\n  id\n  revisionsCount\n  revisionTip {\n    id\n    createdAt\n    author {\n      username\n      ...ProfileLink_user\n      id\n    }\n  }\n  revisionCreated {\n    createdAt\n    author {\n      username\n      ...ProfileLink_user\n      id\n    }\n    id\n  }\n}\n\nfragment CollectionItemToggle_plant on LifeNode {\n  id\n  title\n  myCollectionItem {\n    id\n  }\n}\n\nfragment WishItemToggle_plant on LifeNode {\n  id\n  title\n  myWishItem {\n    id\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n\nfragment PlantLink_plant on LifeNode {\n  id\n  idInt\n  title\n  slug\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a672941544ab0e5dfbabff51d1d46622';
module.exports = node;
