/**
 * @flow
 * @relayHash 82325709e27d6429a7034c1d0c55ef21
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Auth_viewer$ref = any;
type Navbar_me$ref = any;
export type AppQueryVariables = {||};
export type AppQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +me: ?{|
      +isAuthenticated: ?boolean,
      +$fragmentRefs: Navbar_me$ref,
    |},
    +$fragmentRefs: Auth_viewer$ref,
  |}
|};
export type AppQuery = {|
  variables: AppQueryVariables,
  response: AppQueryResponse,
|};
*/


/*
query AppQuery {
  viewer {
    id
    me {
      ...Navbar_me
      isAuthenticated
      id
    }
    ...Auth_viewer
  }
}

fragment Navbar_me on User {
  id
  username
  avatar(width: 40, height: 40) {
    url
  }
  isAuthenticated
  myPerms
}

fragment Auth_viewer on Query {
  id
  me {
    username
    isAuthenticated
    myPerms
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAuthenticated",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AppQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Navbar_me",
                "args": null
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "Auth_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AppQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "avatar",
                "storageKey": "avatar(height:40,width:40)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "height",
                    "value": 40
                  },
                  {
                    "kind": "Literal",
                    "name": "width",
                    "value": 40
                  }
                ],
                "concreteType": "File",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "myPerms",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AppQuery",
    "id": null,
    "text": "query AppQuery {\n  viewer {\n    id\n    me {\n      ...Navbar_me\n      isAuthenticated\n      id\n    }\n    ...Auth_viewer\n  }\n}\n\nfragment Navbar_me on User {\n  id\n  username\n  avatar(width: 40, height: 40) {\n    url\n  }\n  isAuthenticated\n  myPerms\n}\n\nfragment Auth_viewer on Query {\n  id\n  me {\n    username\n    isAuthenticated\n    myPerms\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d36770acf2878464bf492a38ceea783';
module.exports = node;
