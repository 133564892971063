/**
 * @flow
 * @relayHash 7cd53722ce3838922767327962955276
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserActivityList_user$ref = any;
export type ProfileQueryVariables = {|
  username: string
|};
export type ProfileQueryResponse = {|
  +user: ?{|
    +id: string,
    +username: string,
    +firstName: string,
    +reputation: number,
    +avatar: ?{|
      +url: string
    |},
    +collectionList: ?{|
      +totalCount: ?number
    |},
    +wishList: ?{|
      +totalCount: ?number
    |},
    +occurrencesList: ?{|
      +totalCount: ?number
    |},
    +$fragmentRefs: UserActivityList_user$ref,
  |},
  +me: ?{|
    +id: string
  |},
|};
export type ProfileQuery = {|
  variables: ProfileQueryVariables,
  response: ProfileQueryResponse,
|};
*/


/*
query ProfileQuery(
  $username: String!
) {
  user: userByUsername(username: $username) {
    id
    username
    firstName
    reputation
    avatar(width: 180, height: 180) {
      url
    }
    collectionList {
      totalCount
    }
    wishList {
      totalCount
    }
    occurrencesList {
      totalCount
    }
    ...UserActivityList_user
  }
  me {
    id
  }
}

fragment UserActivityList_user on User {
  id
  username
  actions(first: 30) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        ...UserActivityListItem_revision
        __typename
      }
      cursor
    }
  }
}

fragment UserActivityListItem_revision on Revision {
  id
  type
  typeDisplay
  createdAt
  object {
    id
    __typename
    ... on Vote {
      value
    }
    ... on Comment {
      body
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "username",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reputation",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "avatar",
  "storageKey": "avatar(height:180,width:180)",
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 180
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 180
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "collectionList",
  "storageKey": null,
  "args": null,
  "concreteType": "CollectionItemConnection",
  "plural": false,
  "selections": (v7/*: any*/)
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "wishList",
  "storageKey": null,
  "args": null,
  "concreteType": "WishItemConnection",
  "plural": false,
  "selections": (v7/*: any*/)
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "occurrencesList",
  "storageKey": null,
  "args": null,
  "concreteType": "OccurrenceConnection",
  "plural": false,
  "selections": (v7/*: any*/)
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
],
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProfileQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "userByUsername",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "UserActivityList_user",
            "args": null
          }
        ]
      },
      (v11/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProfileQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "userByUsername",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actions",
            "storageKey": "actions(first:30)",
            "args": (v12/*: any*/),
            "concreteType": "RevisionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "RevisionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Revision",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "typeDisplay",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "object",
                        "storageKey": null,
                        "args": null,
                        "concreteType": null,
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v13/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "type": "Vote",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "value",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "type": "Comment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "body",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      (v13/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "actions",
            "args": (v12/*: any*/),
            "handle": "connection",
            "key": "ListActions_actions",
            "filters": null
          }
        ]
      },
      (v11/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProfileQuery",
    "id": null,
    "text": "query ProfileQuery(\n  $username: String!\n) {\n  user: userByUsername(username: $username) {\n    id\n    username\n    firstName\n    reputation\n    avatar(width: 180, height: 180) {\n      url\n    }\n    collectionList {\n      totalCount\n    }\n    wishList {\n      totalCount\n    }\n    occurrencesList {\n      totalCount\n    }\n    ...UserActivityList_user\n  }\n  me {\n    id\n  }\n}\n\nfragment UserActivityList_user on User {\n  id\n  username\n  actions(first: 30) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...UserActivityListItem_revision\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment UserActivityListItem_revision on Revision {\n  id\n  type\n  typeDisplay\n  createdAt\n  object {\n    id\n    __typename\n    ... on Vote {\n      value\n    }\n    ... on Comment {\n      body\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '479e5e1faa3d878f7c54711e1bdaa1bc';
module.exports = node;
