/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PlantLink_plant$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RankDisplay_plant$ref: FragmentReference;
declare export opaque type RankDisplay_plant$fragmentType: RankDisplay_plant$ref;
export type RankDisplay_plant = {|
  +title: string,
  +rankDisplay: ?string,
  +parents: ?$ReadOnlyArray<?{|
    +id: string,
    +idInt: ?number,
    +title: string,
    +slug: ?string,
    +rankDisplay: ?string,
    +$fragmentRefs: PlantLink_plant$ref,
  |}>,
  +$refType: RankDisplay_plant$ref,
|};
export type RankDisplay_plant$data = RankDisplay_plant;
export type RankDisplay_plant$key = {
  +$data?: RankDisplay_plant$data,
  +$fragmentRefs: RankDisplay_plant$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rankDisplay",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RankDisplay_plant",
  "type": "LifeNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parents",
      "storageKey": null,
      "args": null,
      "concreteType": "LifeNode",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "idInt",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "slug",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "FragmentSpread",
          "name": "PlantLink_plant",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '02630fd20dbfe2f8deaffa09a7c722f9';
module.exports = node;
