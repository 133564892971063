/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RevisionType = "CREATE" | "DELETE" | "UPDATE" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserActivityListItem_revision$ref: FragmentReference;
declare export opaque type UserActivityListItem_revision$fragmentType: UserActivityListItem_revision$ref;
export type UserActivityListItem_revision = {|
  +id: string,
  +type: ?RevisionType,
  +typeDisplay: ?string,
  +createdAt: any,
  +object: ?{|
    +id: string,
    +__typename: string,
    +value?: number,
    +body?: string,
  |},
  +$refType: UserActivityListItem_revision$ref,
|};
export type UserActivityListItem_revision$data = UserActivityListItem_revision;
export type UserActivityListItem_revision$key = {
  +$data?: UserActivityListItem_revision$data,
  +$fragmentRefs: UserActivityListItem_revision$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "UserActivityListItem_revision",
  "type": "Revision",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "typeDisplay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "object",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "__typename",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "type": "Vote",
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "value",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "Comment",
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "body",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b18aa2ac0c2b54329e1950237795a83';
module.exports = node;
