/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CommentsList_commenting$ref = any;
type ImageThumbnail_image$ref = any;
type RankDisplay_plant$ref = any;
type RevisionBox_document$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OccurrenceDetails_occurrence$ref: FragmentReference;
declare export opaque type OccurrenceDetails_occurrence$fragmentType: OccurrenceDetails_occurrence$ref;
export type OccurrenceDetails_occurrence = {|
  +id: string,
  +notes: ?string,
  +location: ?{|
    +coordinates: ?any
  |},
  +images: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +smallImage: ?{|
          +url: string
        |},
        +$fragmentRefs: ImageThumbnail_image$ref,
      |}
    |}>
  |},
  +commenting: ?{|
    +$fragmentRefs: CommentsList_commenting$ref
  |},
  +document: ?{|
    +$fragmentRefs: RevisionBox_document$ref
  |},
  +myPerms: ?$ReadOnlyArray<?string>,
  +identity: ?{|
    +id: string,
    +idInt: ?number,
    +title: string,
    +commonName: ?{|
      +name: string
    |},
    +slug: ?string,
    +mainImage: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +smallImage: ?{|
            +url: string
          |},
          +$fragmentRefs: ImageThumbnail_image$ref,
        |}
      |}>
    |},
    +$fragmentRefs: RankDisplay_plant$ref,
  |},
  +$refType: OccurrenceDetails_occurrence$ref,
|};
export type OccurrenceDetails_occurrence$data = OccurrenceDetails_occurrence;
export type OccurrenceDetails_occurrence$key = {
  +$data?: OccurrenceDetails_occurrence$data,
  +$fragmentRefs: OccurrenceDetails_occurrence$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
],
v2 = {
  "kind": "FragmentSpread",
  "name": "ImageThumbnail_image",
  "args": null
};
return {
  "kind": "Fragment",
  "name": "OccurrenceDetails_occurrence",
  "type": "Occurrence",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "location",
      "storageKey": null,
      "args": null,
      "concreteType": "GeometryObjectType",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "coordinates",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "images",
      "storageKey": "images(first:20)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        }
      ],
      "concreteType": "ImageConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ImageEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Image",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": "smallImage",
                  "name": "image",
                  "storageKey": "image(height:645,width:860)",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "height",
                      "value": 645
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 860
                    }
                  ],
                  "concreteType": "File",
                  "plural": false,
                  "selections": (v1/*: any*/)
                },
                (v2/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "commenting",
      "storageKey": null,
      "args": null,
      "concreteType": "Commenting",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CommentsList_commenting",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "document",
      "storageKey": null,
      "args": null,
      "concreteType": "Document",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "RevisionBox_document",
          "args": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "myPerms",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "identity",
      "storageKey": null,
      "args": null,
      "concreteType": "LifeNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "idInt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "commonName",
          "storageKey": null,
          "args": null,
          "concreteType": "CommonName",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "slug",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": "mainImage",
          "name": "images",
          "storageKey": "images(first:1)",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "ImageConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ImageEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Image",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": "smallImage",
                      "name": "image",
                      "storageKey": "image(height:300,width:225)",
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "height",
                          "value": 300
                        },
                        {
                          "kind": "Literal",
                          "name": "width",
                          "value": 225
                        }
                      ],
                      "concreteType": "File",
                      "plural": false,
                      "selections": (v1/*: any*/)
                    },
                    (v2/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "RankDisplay_plant",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fbad99c935341b0b775abfa2167e351d';
module.exports = node;
