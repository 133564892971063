/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PlantLink_plant$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaxoClimb_lifeNode$ref: FragmentReference;
declare export opaque type TaxoClimb_lifeNode$fragmentType: TaxoClimb_lifeNode$ref;
export type TaxoClimb_lifeNode = {|
  +children: ?{|
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +rankDisplay: ?string,
        +$fragmentRefs: PlantLink_plant$ref,
      |}
    |}>,
  |},
  +$refType: TaxoClimb_lifeNode$ref,
|};
export type TaxoClimb_lifeNode$data = TaxoClimb_lifeNode;
export type TaxoClimb_lifeNode$key = {
  +$data?: TaxoClimb_lifeNode$data,
  +$fragmentRefs: TaxoClimb_lifeNode$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "TaxoClimb_lifeNode",
  "type": "LifeNode",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "children"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 30
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "children",
      "name": "__ListLifeNode_children_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "LifeNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "LifeNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "LifeNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "rankDisplay",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "PlantLink_plant",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '536f7162c0ec6bc417f0004c9790687c';
module.exports = node;
