/**
 * @flow
 * @relayHash a1e2294aa3beb7f6771736c8d3795934
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ImageThumbnail_image$ref = any;
export type ImageCreateInput = {|
  parent: string,
  description?: ?string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type AddImageMutationVariables = {|
  input: ImageCreateInput
|};
export type AddImageMutationResponse = {|
  +imageCreate: ?{|
    +image: ?{|
      +node: ?{|
        +id: string,
        +smallImage: ?{|
          +url: string
        |},
        +$fragmentRefs: ImageThumbnail_image$ref,
      |}
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type AddImageMutation = {|
  variables: AddImageMutationVariables,
  response: AddImageMutationResponse,
|};
*/


/*
mutation AddImageMutation(
  $input: ImageCreateInput!
) {
  imageCreate(input: $input) {
    image {
      node {
        id
        smallImage: image(width: 200, height: 200) {
          url
        }
        ...ImageThumbnail_image
      }
    }
    errors {
      code
      location
      message
    }
  }
}

fragment ImageThumbnail_image on Image {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImageCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": "smallImage",
  "name": "image",
  "storageKey": "image(height:200,width:200)",
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 200
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 200
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddImageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "imageCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ImageCreatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "ImageEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Image",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "ImageThumbnail_image",
                    "args": null
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddImageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "imageCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ImageCreatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "ImageEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Image",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddImageMutation",
    "id": null,
    "text": "mutation AddImageMutation(\n  $input: ImageCreateInput!\n) {\n  imageCreate(input: $input) {\n    image {\n      node {\n        id\n        smallImage: image(width: 200, height: 200) {\n          url\n        }\n        ...ImageThumbnail_image\n      }\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n\nfragment ImageThumbnail_image on Image {\n  id\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13ae522c5fc41e4f93602537816c4e6f';
module.exports = node;
