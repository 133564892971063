/**
 * @flow
 * @relayHash d294bdafb5e7f31009198d31e0745619
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProfileLink_user$ref = any;
export type CollectionItemAddInput = {|
  plantId: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type CollectionItemAddMutationVariables = {|
  input: CollectionItemAddInput
|};
export type CollectionItemAddMutationResponse = {|
  +collectionItemAdd: ?{|
    +collectionItem: ?{|
      +node: ?{|
        +id: string,
        +user: ?{|
          +id: string,
          +username: string,
          +avatar: ?{|
            +url: string
          |},
          +$fragmentRefs: ProfileLink_user$ref,
        |},
        +plant: ?{|
          +id: string,
          +collectionList: ?{|
            +totalCount: ?number
          |},
          +myCollectionItem: ?{|
            +id: string
          |},
        |},
      |}
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type CollectionItemAddMutation = {|
  variables: CollectionItemAddMutationVariables,
  response: CollectionItemAddMutationResponse,
|};
*/


/*
mutation CollectionItemAddMutation(
  $input: CollectionItemAddInput!
) {
  collectionItemAdd(input: $input) {
    collectionItem {
      node {
        id
        user {
          id
          username
          avatar(width: 40, height: 40) {
            url
          }
          ...ProfileLink_user
        }
        plant {
          id
          collectionList {
            totalCount
          }
          myCollectionItem {
            id
          }
        }
      }
    }
    errors {
      code
      location
      message
    }
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CollectionItemAddInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "avatar",
  "storageKey": "avatar(height:40,width:40)",
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 40
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 40
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "plant",
  "storageKey": null,
  "args": null,
  "concreteType": "LifeNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "collectionList",
      "storageKey": null,
      "args": null,
      "concreteType": "CollectionItemConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "myCollectionItem",
      "storageKey": null,
      "args": null,
      "concreteType": "CollectionItem",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ]
    }
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CollectionItemAddMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "collectionItemAdd",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionItemAddPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "collectionItem",
            "storageKey": null,
            "args": null,
            "concreteType": "CollectionItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CollectionItem",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "ProfileLink_user",
                        "args": null
                      }
                    ]
                  },
                  (v5/*: any*/)
                ]
              }
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CollectionItemAddMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "collectionItemAdd",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionItemAddPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "collectionItem",
            "storageKey": null,
            "args": null,
            "concreteType": "CollectionItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CollectionItem",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reputation",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  (v5/*: any*/)
                ]
              }
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CollectionItemAddMutation",
    "id": null,
    "text": "mutation CollectionItemAddMutation(\n  $input: CollectionItemAddInput!\n) {\n  collectionItemAdd(input: $input) {\n    collectionItem {\n      node {\n        id\n        user {\n          id\n          username\n          avatar(width: 40, height: 40) {\n            url\n          }\n          ...ProfileLink_user\n        }\n        plant {\n          id\n          collectionList {\n            totalCount\n          }\n          myCollectionItem {\n            id\n          }\n        }\n      }\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '166422f9a39237ba2fb9cfe7bce6e471';
module.exports = node;
