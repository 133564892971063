/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProfileLink_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ImageThumbnail_image$ref: FragmentReference;
declare export opaque type ImageThumbnail_image$fragmentType: ImageThumbnail_image$ref;
export type ImageThumbnail_image = {|
  +id: string,
  +bigImage?: ?{|
    +url: string
  |},
  +myPerms?: ?$ReadOnlyArray<?string>,
  +revisionCreated?: ?{|
    +createdAt: any,
    +author: ?{|
      +id: string,
      +username: string,
      +avatar: ?{|
        +url: string
      |},
      +$fragmentRefs: ProfileLink_user$ref,
    |},
  |},
  +commenting?: ?{|
    +id: string,
    +count: ?number,
  |},
  +$refType: ImageThumbnail_image$ref,
|};
export type ImageThumbnail_image$data = ImageThumbnail_image;
export type ImageThumbnail_image$key = {
  +$data?: ImageThumbnail_image$data,
  +$fragmentRefs: ImageThumbnail_image$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "ImageThumbnail_image",
  "type": "Image",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "isOpen",
      "type": "Boolean!",
      "defaultValue": false
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "isOpen",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": "bigImage",
          "name": "image",
          "storageKey": "image(height:825,width:1200)",
          "args": [
            {
              "kind": "Literal",
              "name": "height",
              "value": 825
            },
            {
              "kind": "Literal",
              "name": "width",
              "value": 1200
            }
          ],
          "concreteType": "File",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "myPerms",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "revisionCreated",
          "storageKey": null,
          "args": null,
          "concreteType": "Revision",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "author",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "username",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "avatar",
                  "storageKey": "avatar(height:40,width:40)",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "height",
                      "value": 40
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 40
                    }
                  ],
                  "concreteType": "File",
                  "plural": false,
                  "selections": (v1/*: any*/)
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ProfileLink_user",
                  "args": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "commenting",
          "storageKey": null,
          "args": null,
          "concreteType": "Commenting",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "count",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa9713e2af1cec260b59d51a1e4ce6b3';
module.exports = node;
