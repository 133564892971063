/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlantItem_lifeNode$ref: FragmentReference;
declare export opaque type PlantItem_lifeNode$fragmentType: PlantItem_lifeNode$ref;
export type PlantItem_lifeNode = {|
  +id: string,
  +idInt: ?number,
  +title: string,
  +slug: ?string,
  +rankDisplay: ?string,
  +commonName: ?{|
    +name: string
  |},
  +images: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +bigImage: ?{|
          +url: string
        |},
      |}
    |}>
  |},
  +commonNames: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +language: ?string,
      |}
    |}>
  |},
  +$refType: PlantItem_lifeNode$ref,
|};
export type PlantItem_lifeNode$data = PlantItem_lifeNode;
export type PlantItem_lifeNode$key = {
  +$data?: PlantItem_lifeNode$data,
  +$fragmentRefs: PlantItem_lifeNode$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PlantItem_lifeNode",
  "type": "LifeNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "idInt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rankDisplay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "commonName",
      "storageKey": null,
      "args": null,
      "concreteType": "CommonName",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "images",
      "storageKey": "images(first:1)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "ImageConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ImageEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Image",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": "bigImage",
                  "name": "image",
                  "storageKey": "image(height:520,width:440)",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "height",
                      "value": 520
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 440
                    }
                  ],
                  "concreteType": "File",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "url",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "commonNames",
      "storageKey": "commonNames(first:20)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        }
      ],
      "concreteType": "CommonNameConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CommonNameEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CommonName",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "language",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e73f0417cf60411ebbf1b177c1689e8';
module.exports = node;
