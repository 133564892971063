/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CommentsList_commenting$ref = any;
type ProfileLink_user$ref = any;
type VotingButtons_voting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommentsItem_comment$ref: FragmentReference;
declare export opaque type CommentsItem_comment$fragmentType: CommentsItem_comment$ref;
export type CommentsItem_comment = {|
  +id: string,
  +myPerms: ?$ReadOnlyArray<?string>,
  +body: string,
  +document: ?{|
    +revisionsCount: number
  |},
  +revisionCreated: ?{|
    +createdAt: any,
    +author: ?{|
      +id: string,
      +username: string,
      +avatar: ?{|
        +url: string
      |},
      +$fragmentRefs: ProfileLink_user$ref,
    |},
  |},
  +voting: ?{|
    +$fragmentRefs: VotingButtons_voting$ref
  |},
  +commenting: ?{|
    +id: string,
    +count: ?number,
    +$fragmentRefs: CommentsList_commenting$ref,
  |},
  +$refType: CommentsItem_comment$ref,
|};
export type CommentsItem_comment$data = CommentsItem_comment;
export type CommentsItem_comment$key = {
  +$data?: CommentsItem_comment$data,
  +$fragmentRefs: CommentsItem_comment$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CommentsItem_comment",
  "type": "Comment",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "isRepliesExpanded",
      "type": "Boolean!",
      "defaultValue": false
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "myPerms",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "body",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "document",
      "storageKey": null,
      "args": null,
      "concreteType": "Document",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "revisionsCount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "revisionCreated",
      "storageKey": null,
      "args": null,
      "concreteType": "Revision",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "author",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "username",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "avatar",
              "storageKey": "avatar(height:40,width:40)",
              "args": [
                {
                  "kind": "Literal",
                  "name": "height",
                  "value": 40
                },
                {
                  "kind": "Literal",
                  "name": "width",
                  "value": 40
                }
              ],
              "concreteType": "File",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "url",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "ProfileLink_user",
              "args": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "voting",
      "storageKey": null,
      "args": null,
      "concreteType": "Voting",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "VotingButtons_voting",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "commenting",
      "storageKey": null,
      "args": null,
      "concreteType": "Commenting",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "Condition",
          "passingValue": true,
          "condition": "isRepliesExpanded",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "CommentsList_commenting",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '264207184e6bd64431e3bd2b4b20a3bf';
module.exports = node;
