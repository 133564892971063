/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlantSelectField_viewer$ref: FragmentReference;
declare export opaque type PlantSelectField_viewer$fragmentType: PlantSelectField_viewer$ref;
export type PlantSelectField_viewer = {|
  +id: string,
  +allLifeNode: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
        +rankDisplay: ?string,
        +images: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +mainImage: ?{|
                +url: string
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: PlantSelectField_viewer$ref,
|};
export type PlantSelectField_viewer$data = PlantSelectField_viewer;
export type PlantSelectField_viewer$key = {
  +$data?: PlantSelectField_viewer$data,
  +$fragmentRefs: PlantSelectField_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PlantSelectField_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "exclude",
      "type": "Int",
      "defaultValue": null
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "allLifeNode",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "exclude",
          "variableName": "exclude"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "LifeNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "LifeNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "LifeNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "rankDisplay",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "images",
                  "storageKey": "images(first:1)",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    }
                  ],
                  "concreteType": "ImageConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ImageEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Image",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": "mainImage",
                              "name": "image",
                              "storageKey": "image(height:200,width:200)",
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "height",
                                  "value": 200
                                },
                                {
                                  "kind": "Literal",
                                  "name": "width",
                                  "value": 200
                                }
                              ],
                              "concreteType": "File",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "url",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae076b7f42ba6bba800359cced856f89';
module.exports = node;
