/**
 * @flow
 * @relayHash ae418d0fa13ccc9d348049ba3b874b47
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommentsItem_comment$ref = any;
export type CommentEditInput = {|
  id: string,
  body: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type CommentEditMutationVariables = {|
  input: CommentEditInput
|};
export type CommentEditMutationResponse = {|
  +commentEdit: ?{|
    +comment: ?{|
      +id: string,
      +$fragmentRefs: CommentsItem_comment$ref,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type CommentEditMutation = {|
  variables: CommentEditMutationVariables,
  response: CommentEditMutationResponse,
|};
*/


/*
mutation CommentEditMutation(
  $input: CommentEditInput!
) {
  commentEdit(input: $input) {
    comment {
      id
      ...CommentsItem_comment
    }
    errors {
      code
      location
      message
    }
  }
}

fragment CommentsItem_comment on Comment {
  id
  myPerms
  body
  document {
    revisionsCount
    id
  }
  revisionCreated {
    createdAt
    author {
      id
      username
      avatar(width: 40, height: 40) {
        url
      }
      ...ProfileLink_user
    }
    id
  }
  voting {
    ...VotingButtons_voting
    id
  }
  commenting {
    id
    count
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}

fragment VotingButtons_voting on Voting {
  id
  countUps
  countDowns
  sumValues
  mine {
    id
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CommentEditInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CommentEditMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "commentEdit",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CommentEditPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comment",
            "storageKey": null,
            "args": null,
            "concreteType": "Comment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "CommentsItem_comment",
                "args": null
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CommentEditMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "commentEdit",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CommentEditPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comment",
            "storageKey": null,
            "args": null,
            "concreteType": "Comment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "myPerms",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "body",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "document",
                "storageKey": null,
                "args": null,
                "concreteType": "Document",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "revisionsCount",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "revisionCreated",
                "storageKey": null,
                "args": null,
                "concreteType": "Revision",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "avatar",
                        "storageKey": "avatar(height:40,width:40)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "height",
                            "value": 40
                          },
                          {
                            "kind": "Literal",
                            "name": "width",
                            "value": 40
                          }
                        ],
                        "concreteType": "File",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "url",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reputation",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "voting",
                "storageKey": null,
                "args": null,
                "concreteType": "Voting",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "countUps",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "countDowns",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "sumValues",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "mine",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Vote",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "value",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "commenting",
                "storageKey": null,
                "args": null,
                "concreteType": "Commenting",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "count",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CommentEditMutation",
    "id": null,
    "text": "mutation CommentEditMutation(\n  $input: CommentEditInput!\n) {\n  commentEdit(input: $input) {\n    comment {\n      id\n      ...CommentsItem_comment\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n\nfragment CommentsItem_comment on Comment {\n  id\n  myPerms\n  body\n  document {\n    revisionsCount\n    id\n  }\n  revisionCreated {\n    createdAt\n    author {\n      id\n      username\n      avatar(width: 40, height: 40) {\n        url\n      }\n      ...ProfileLink_user\n    }\n    id\n  }\n  voting {\n    ...VotingButtons_voting\n    id\n  }\n  commenting {\n    id\n    count\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n\nfragment VotingButtons_voting on Voting {\n  id\n  countUps\n  countDowns\n  sumValues\n  mine {\n    id\n    value\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f64442754b944a99b4908dd71979faf0';
module.exports = node;
