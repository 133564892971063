/**
 * @flow
 * @relayHash 39ffe34257de18abee13e1f63c868c17
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserPlantList_viewer$ref = any;
export type UserPlantListQueryVariables = {|
  count: number,
  cursor?: ?string,
  username: string,
|};
export type UserPlantListQueryResponse = {|
  +user: ?{|
    +id: string,
    +$fragmentRefs: UserPlantList_viewer$ref,
  |},
  +me: ?{|
    +id: string
  |},
|};
export type UserPlantListQuery = {|
  variables: UserPlantListQueryVariables,
  response: UserPlantListQueryResponse,
|};
*/


/*
query UserPlantListQuery(
  $count: Int!
  $cursor: String
  $username: String!
) {
  user: userByUsername(username: $username) {
    id
    ...UserPlantList_viewer_1G22uz
  }
  me {
    id
  }
}

fragment UserPlantList_viewer_1G22uz on User {
  id
  username
  firstName
  wishList(first: $count, after: $cursor) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        plant {
          id
          ...PlantItem_lifeNode
        }
        __typename
      }
      cursor
    }
  }
}

fragment PlantItem_lifeNode on LifeNode {
  id
  idInt
  title
  slug
  rankDisplay
  commonName {
    name
    id
  }
  images(first: 1) {
    edges {
      node {
        id
        bigImage: image(width: 440, height: 520) {
          url
        }
      }
    }
  }
  commonNames(first: 20) {
    edges {
      node {
        id
        name
        language
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "username",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserPlantListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "userByUsername",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "UserPlantList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ]
          }
        ]
      },
      (v3/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserPlantListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "userByUsername",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "wishList",
            "storageKey": null,
            "args": (v4/*: any*/),
            "concreteType": "WishItemConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "WishItemEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WishItem",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "plant",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LifeNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "idInt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "slug",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "rankDisplay",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "commonName",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommonName",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "images",
                            "storageKey": "images(first:1)",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1
                              }
                            ],
                            "concreteType": "ImageConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ImageEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": "bigImage",
                                        "name": "image",
                                        "storageKey": "image(height:520,width:440)",
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "height",
                                            "value": 520
                                          },
                                          {
                                            "kind": "Literal",
                                            "name": "width",
                                            "value": 440
                                          }
                                        ],
                                        "concreteType": "File",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "url",
                                            "args": null,
                                            "storageKey": null
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "commonNames",
                            "storageKey": "commonNames(first:20)",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 20
                              }
                            ],
                            "concreteType": "CommonNameConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CommonNameEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommonName",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v5/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "language",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "wishList",
            "args": (v4/*: any*/),
            "handle": "connection",
            "key": "UserPlantList_wishList",
            "filters": null
          }
        ]
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserPlantListQuery",
    "id": null,
    "text": "query UserPlantListQuery(\n  $count: Int!\n  $cursor: String\n  $username: String!\n) {\n  user: userByUsername(username: $username) {\n    id\n    ...UserPlantList_viewer_1G22uz\n  }\n  me {\n    id\n  }\n}\n\nfragment UserPlantList_viewer_1G22uz on User {\n  id\n  username\n  firstName\n  wishList(first: $count, after: $cursor) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        plant {\n          id\n          ...PlantItem_lifeNode\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment PlantItem_lifeNode on LifeNode {\n  id\n  idInt\n  title\n  slug\n  rankDisplay\n  commonName {\n    name\n    id\n  }\n  images(first: 1) {\n    edges {\n      node {\n        id\n        bigImage: image(width: 440, height: 520) {\n          url\n        }\n      }\n    }\n  }\n  commonNames(first: 20) {\n    edges {\n      node {\n        id\n        name\n        language\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0ab3113a1856bcc9a9c05e1ef93050b';
module.exports = node;
