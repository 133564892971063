/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Auth_viewer$ref: FragmentReference;
declare export opaque type Auth_viewer$fragmentType: Auth_viewer$ref;
export type Auth_viewer = {|
  +id: string,
  +me: ?{|
    +username: string,
    +isAuthenticated: ?boolean,
    +myPerms: ?$ReadOnlyArray<?string>,
  |},
  +$refType: Auth_viewer$ref,
|};
export type Auth_viewer$data = Auth_viewer;
export type Auth_viewer$key = {
  +$data?: Auth_viewer$data,
  +$fragmentRefs: Auth_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Auth_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "me",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isAuthenticated",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "myPerms",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8a5279956e5951fd85103df483a0c65e';
module.exports = node;
