/**
 * @flow
 * @relayHash 198c1251b11fd761d7529a85215f5bd1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PlantOccurrencesQueryVariables = {|
  plantID: number
|};
export type PlantOccurrencesQueryResponse = {|
  +plant: ?{|
    +id: string,
    +idInt: ?number,
    +title: string,
  |}
|};
export type PlantOccurrencesQuery = {|
  variables: PlantOccurrencesQueryVariables,
  response: PlantOccurrencesQueryResponse,
|};
*/


/*
query PlantOccurrencesQuery(
  $plantID: Int!
) {
  plant: lifeNodeByIntID(documentId: $plantID) {
    id
    idInt
    title
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "plantID",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "plant",
    "name": "lifeNodeByIntID",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "plantID"
      }
    ],
    "concreteType": "LifeNode",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "idInt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantOccurrencesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantOccurrencesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "PlantOccurrencesQuery",
    "id": null,
    "text": "query PlantOccurrencesQuery(\n  $plantID: Int!\n) {\n  plant: lifeNodeByIntID(documentId: $plantID) {\n    id\n    idInt\n    title\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '392f9a22a791029f44f0d70edf5d6846';
module.exports = node;
