/**
 * @flow
 * @relayHash 6ce720d7bd1dc4faad41a8ea61577f47
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VotingButtons_voting$ref = any;
export type VoteSetInput = {|
  parent: string,
  value: number,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type VotingButtonsSetMutationVariables = {|
  input: VoteSetInput
|};
export type VotingButtonsSetMutationResponse = {|
  +voteSet: ?{|
    +voting: ?{|
      +$fragmentRefs: VotingButtons_voting$ref
    |},
    +vote: ?{|
      +id: string,
      +value: number,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type VotingButtonsSetMutation = {|
  variables: VotingButtonsSetMutationVariables,
  response: VotingButtonsSetMutationResponse,
|};
*/


/*
mutation VotingButtonsSetMutation(
  $input: VoteSetInput!
) {
  voteSet(input: $input) {
    voting {
      ...VotingButtons_voting
      id
    }
    vote {
      id
      value
    }
    errors {
      code
      location
      message
    }
  }
}

fragment VotingButtons_voting on Voting {
  id
  countUps
  countDowns
  sumValues
  mine {
    id
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "VoteSetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "value",
    "args": null,
    "storageKey": null
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vote",
  "storageKey": null,
  "args": null,
  "concreteType": "Vote",
  "plural": false,
  "selections": (v3/*: any*/)
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VotingButtonsSetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "voteSet",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VoteSetPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "voting",
            "storageKey": null,
            "args": null,
            "concreteType": "Voting",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "VotingButtons_voting",
                "args": null
              }
            ]
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VotingButtonsSetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "voteSet",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VoteSetPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "voting",
            "storageKey": null,
            "args": null,
            "concreteType": "Voting",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "countUps",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "countDowns",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sumValues",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "mine",
                "storageKey": null,
                "args": null,
                "concreteType": "Vote",
                "plural": false,
                "selections": (v3/*: any*/)
              }
            ]
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "VotingButtonsSetMutation",
    "id": null,
    "text": "mutation VotingButtonsSetMutation(\n  $input: VoteSetInput!\n) {\n  voteSet(input: $input) {\n    voting {\n      ...VotingButtons_voting\n      id\n    }\n    vote {\n      id\n      value\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n\nfragment VotingButtons_voting on Voting {\n  id\n  countUps\n  countDowns\n  sumValues\n  mine {\n    id\n    value\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f21408a3de4ba7e3b8bac6d4d393678e';
module.exports = node;
