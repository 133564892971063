/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Navbar_me$ref: FragmentReference;
declare export opaque type Navbar_me$fragmentType: Navbar_me$ref;
export type Navbar_me = {|
  +id: string,
  +username: string,
  +avatar: ?{|
    +url: string
  |},
  +isAuthenticated: ?boolean,
  +myPerms: ?$ReadOnlyArray<?string>,
  +$refType: Navbar_me$ref,
|};
export type Navbar_me$data = Navbar_me;
export type Navbar_me$key = {
  +$data?: Navbar_me$data,
  +$fragmentRefs: Navbar_me$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Navbar_me",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "avatar",
      "storageKey": "avatar(height:40,width:40)",
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 40
        },
        {
          "kind": "Literal",
          "name": "width",
          "value": 40
        }
      ],
      "concreteType": "File",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAuthenticated",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "myPerms",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0c01889debdb56320936b5633c982d66';
module.exports = node;
