/**
 * @flow
 * @relayHash 408dc82c99d65fed2a52330af2f21ba0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OccurrenceAddQueryVariables = {||};
export type OccurrenceAddQueryResponse = {|
  +viewer: ?{|
    +id: string
  |},
  +occurranceTypes: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +trunkTypes: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +canopyPositions: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
  +healthStates: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
|};
export type OccurrenceAddQuery = {|
  variables: OccurrenceAddQueryVariables,
  response: OccurrenceAddQueryResponse,
|};
*/


/*
query OccurrenceAddQuery {
  viewer {
    id
  }
  occurranceTypes: __type(name: "OccurranceType") {
    enumValues {
      name
      description
    }
  }
  trunkTypes: __type(name: "TrunkType") {
    enumValues {
      name
      description
    }
  }
  canopyPositions: __type(name: "CanopyPosition") {
    enumValues {
      name
      description
    }
  }
  healthStates: __type(name: "HealthState") {
    enumValues {
      name
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "enumValues",
    "storageKey": null,
    "args": null,
    "concreteType": "__EnumValue",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "viewer",
    "storageKey": null,
    "args": null,
    "concreteType": "Query",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": "occurranceTypes",
    "name": "__type",
    "storageKey": "__type(name:\"OccurranceType\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "OccurranceType"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "trunkTypes",
    "name": "__type",
    "storageKey": "__type(name:\"TrunkType\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "TrunkType"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "canopyPositions",
    "name": "__type",
    "storageKey": "__type(name:\"CanopyPosition\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "CanopyPosition"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": "healthStates",
    "name": "__type",
    "storageKey": "__type(name:\"HealthState\")",
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "HealthState"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": (v0/*: any*/)
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OccurrenceAddQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OccurrenceAddQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "OccurrenceAddQuery",
    "id": null,
    "text": "query OccurrenceAddQuery {\n  viewer {\n    id\n  }\n  occurranceTypes: __type(name: \"OccurranceType\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  trunkTypes: __type(name: \"TrunkType\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  canopyPositions: __type(name: \"CanopyPosition\") {\n    enumValues {\n      name\n      description\n    }\n  }\n  healthStates: __type(name: \"HealthState\") {\n    enumValues {\n      name\n      description\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c200aadad2fc6e633dc5a937c844e7f';
module.exports = node;
