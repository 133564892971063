/**
 * @flow
 * @relayHash 090c21d3a52b667518e999d92344d7db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VotingButtons_voting$ref = any;
export type VoteDeleteInput = {|
  id: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type VotingButtonsDeleteMutationVariables = {|
  input: VoteDeleteInput
|};
export type VotingButtonsDeleteMutationResponse = {|
  +voteDelete: ?{|
    +voting: ?{|
      +$fragmentRefs: VotingButtons_voting$ref
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type VotingButtonsDeleteMutation = {|
  variables: VotingButtonsDeleteMutationVariables,
  response: VotingButtonsDeleteMutationResponse,
|};
*/


/*
mutation VotingButtonsDeleteMutation(
  $input: VoteDeleteInput!
) {
  voteDelete(input: $input) {
    voting {
      ...VotingButtons_voting
      id
    }
    errors {
      code
      location
      message
    }
  }
}

fragment VotingButtons_voting on Voting {
  id
  countUps
  countDowns
  sumValues
  mine {
    id
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "VoteDeleteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VotingButtonsDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "voteDelete",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VoteDeletePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "voting",
            "storageKey": null,
            "args": null,
            "concreteType": "Voting",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "VotingButtons_voting",
                "args": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VotingButtonsDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "voteDelete",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VoteDeletePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "voting",
            "storageKey": null,
            "args": null,
            "concreteType": "Voting",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "countUps",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "countDowns",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sumValues",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "mine",
                "storageKey": null,
                "args": null,
                "concreteType": "Vote",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "value",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "VotingButtonsDeleteMutation",
    "id": null,
    "text": "mutation VotingButtonsDeleteMutation(\n  $input: VoteDeleteInput!\n) {\n  voteDelete(input: $input) {\n    voting {\n      ...VotingButtons_voting\n      id\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n\nfragment VotingButtons_voting on Voting {\n  id\n  countUps\n  countDowns\n  sumValues\n  mine {\n    id\n    value\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfb5f436f3d7729a7d704fa187356cca';
module.exports = node;
