/**
 * @flow
 * @relayHash 12f126466bc0ce11ec7d0c260607c6c1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PostCreateInput = {|
  url: string,
  title: string,
  body: string,
  summary?: ?string,
  publishedAt: any,
  mainImage?: ?string,
  tags?: ?string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type PostCreateMutationVariables = {|
  input: PostCreateInput
|};
export type PostCreateMutationResponse = {|
  +postCreate: ?{|
    +post: ?{|
      +id: string,
      +title: string,
      +url: string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type PostCreateMutation = {|
  variables: PostCreateMutationVariables,
  response: PostCreateMutationResponse,
|};
*/


/*
mutation PostCreateMutation(
  $input: PostCreateInput!
) {
  postCreate(input: $input) {
    post {
      id
      title
      url
    }
    errors {
      code
      location
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PostCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "postCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PostCreatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "post",
        "storageKey": null,
        "args": null,
        "concreteType": "Post",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PostCreateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PostCreateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PostCreateMutation",
    "id": null,
    "text": "mutation PostCreateMutation(\n  $input: PostCreateInput!\n) {\n  postCreate(input: $input) {\n    post {\n      id\n      title\n      url\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6a025f4faa52744d7345ad580f0efc39';
module.exports = node;
