/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CommonNameItem_commonName$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommonNamesDialog__commonNames$ref: FragmentReference;
declare export opaque type CommonNamesDialog__commonNames$fragmentType: CommonNamesDialog__commonNames$ref;
export type CommonNamesDialog__commonNames = {|
  +id: string,
  +myPerms: ?$ReadOnlyArray<?string>,
  +allCommonNames: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: CommonNameItem_commonName$ref,
      |}
    |}>
  |},
  +commonNamesStats: ?{|
    +totalCount: ?number
  |},
  +$refType: CommonNamesDialog__commonNames$ref,
|};
export type CommonNamesDialog__commonNames$data = CommonNamesDialog__commonNames;
export type CommonNamesDialog__commonNames$key = {
  +$data?: CommonNamesDialog__commonNames$data,
  +$fragmentRefs: CommonNamesDialog__commonNames$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CommonNamesDialog__commonNames",
  "type": "LifeNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "myPerms",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "allCommonNames",
      "name": "commonNames",
      "storageKey": null,
      "args": null,
      "concreteType": "CommonNameConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CommonNameEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CommonName",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "FragmentSpread",
                  "name": "CommonNameItem_commonName",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "commonNamesStats",
      "name": "commonNames",
      "storageKey": null,
      "args": null,
      "concreteType": "CommonNameConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '49399eba56ed698859e58dcb3515a657';
module.exports = node;
