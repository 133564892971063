/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PlantLink_plant$ref = any;
type ProfileLink_user$ref = any;
type VotingButtons_voting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SuggestionItem_suggestionID$ref: FragmentReference;
declare export opaque type SuggestionItem_suggestionID$fragmentType: SuggestionItem_suggestionID$ref;
export type SuggestionItem_suggestionID = {|
  +id: string,
  +myPerms: ?$ReadOnlyArray<?string>,
  +notes: ?string,
  +author: ?{|
    +avatar: ?{|
      +url: string
    |},
    +username: string,
    +$fragmentRefs: ProfileLink_user$ref,
  |},
  +identity: ?{|
    +id: string,
    +rankDisplay: ?string,
    +description: ?string,
    +$fragmentRefs: PlantLink_plant$ref,
  |},
  +voting: ?{|
    +$fragmentRefs: VotingButtons_voting$ref
  |},
  +revisionCreated: ?{|
    +createdAt: any
  |},
  +$refType: SuggestionItem_suggestionID$ref,
|};
export type SuggestionItem_suggestionID$data = SuggestionItem_suggestionID;
export type SuggestionItem_suggestionID$key = {
  +$data?: SuggestionItem_suggestionID$data,
  +$fragmentRefs: SuggestionItem_suggestionID$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SuggestionItem_suggestionID",
  "type": "SuggestionID",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "myPerms",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "author",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "avatar",
          "storageKey": "avatar(height:40,width:40)",
          "args": [
            {
              "kind": "Literal",
              "name": "height",
              "value": 40
            },
            {
              "kind": "Literal",
              "name": "width",
              "value": 40
            }
          ],
          "concreteType": "File",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ProfileLink_user",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "identity",
      "storageKey": null,
      "args": null,
      "concreteType": "LifeNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "rankDisplay",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "PlantLink_plant",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "voting",
      "storageKey": null,
      "args": null,
      "concreteType": "Voting",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "VotingButtons_voting",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "revisionCreated",
      "storageKey": null,
      "args": null,
      "concreteType": "Revision",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ecf5660b4e3d4affd08a611e7bf578d6';
module.exports = node;
