/**
 * @flow
 * @relayHash c306c2794365c816be45669f6f55d3a1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProfileLink_user$ref = any;
export type PlantWishListQueryVariables = {|
  plantID: number
|};
export type PlantWishListQueryResponse = {|
  +plant: ?{|
    +id: string,
    +idInt: ?number,
    +title: string,
    +wishList: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +user: ?{|
            +id: string,
            +username: string,
            +avatar: ?{|
              +url: string
            |},
            +$fragmentRefs: ProfileLink_user$ref,
          |},
        |}
      |}>
    |},
  |}
|};
export type PlantWishListQuery = {|
  variables: PlantWishListQueryVariables,
  response: PlantWishListQueryResponse,
|};
*/


/*
query PlantWishListQuery(
  $plantID: Int!
) {
  plant: lifeNodeByIntID(documentId: $plantID) {
    id
    idInt
    title
    wishList(first: 20) {
      edges {
        node {
          id
          user {
            id
            username
            avatar(width: 40, height: 40) {
              url
            }
            ...ProfileLink_user
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "plantID",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "documentId",
    "variableName": "plantID"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idInt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "avatar",
  "storageKey": "avatar(height:40,width:40)",
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 40
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 40
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PlantWishListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "plant",
        "name": "lifeNodeByIntID",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "wishList",
            "name": "__Plant_wishList_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "WishItemConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "WishItemEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WishItem",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "ProfileLink_user",
                            "args": null
                          }
                        ]
                      },
                      (v7/*: any*/)
                    ]
                  },
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PlantWishListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "plant",
        "name": "lifeNodeByIntID",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LifeNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "wishList",
            "storageKey": "wishList(first:20)",
            "args": (v10/*: any*/),
            "concreteType": "WishItemConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "WishItemEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WishItem",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "reputation",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      (v7/*: any*/)
                    ]
                  },
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "wishList",
            "args": (v10/*: any*/),
            "handle": "connection",
            "key": "Plant_wishList",
            "filters": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PlantWishListQuery",
    "id": null,
    "text": "query PlantWishListQuery(\n  $plantID: Int!\n) {\n  plant: lifeNodeByIntID(documentId: $plantID) {\n    id\n    idInt\n    title\n    wishList(first: 20) {\n      edges {\n        node {\n          id\n          user {\n            id\n            username\n            avatar(width: 40, height: 40) {\n              url\n            }\n            ...ProfileLink_user\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "plant",
            "wishList"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '35e4ea653a7410476138a26fbe0582f2';
module.exports = node;
