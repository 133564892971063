/**
 * @flow
 * @relayHash 869291dec522e658653abb3260b67169
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommentsList_commenting$ref = any;
export type CommentsListQueryVariables = {|
  count: number,
  cursor?: ?string,
  nodeID: string,
|};
export type CommentsListQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: CommentsList_commenting$ref
  |}
|};
export type CommentsListQuery = {|
  variables: CommentsListQueryVariables,
  response: CommentsListQueryResponse,
|};
*/


/*
query CommentsListQuery(
  $count: Int!
  $cursor: String
  $nodeID: ID!
) {
  node(id: $nodeID) {
    __typename
    ...CommentsList_commenting_1G22uz
    id
  }
}

fragment CommentsList_commenting_1G22uz on Commenting {
  id
  comments(first: $count, after: $cursor) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        ...CommentsItem_comment
        __typename
      }
      cursor
    }
  }
}

fragment CommentsItem_comment on Comment {
  id
  myPerms
  body
  document {
    revisionsCount
    id
  }
  revisionCreated {
    createdAt
    author {
      id
      username
      avatar(width: 40, height: 40) {
        url
      }
      ...ProfileLink_user
    }
    id
  }
  voting {
    ...VotingButtons_voting
    id
  }
  commenting {
    id
    count
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}

fragment VotingButtons_voting on Voting {
  id
  countUps
  countDowns
  sumValues
  mine {
    id
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "nodeID",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "nodeID"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CommentsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CommentsList_commenting",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CommentsListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Commenting",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "comments",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "CommentConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endCursor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasNextPage",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Comment",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "myPerms",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "body",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "document",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Document",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "revisionsCount",
                                "args": null,
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "revisionCreated",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Revision",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "createdAt",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "author",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "User",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "username",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "avatar",
                                    "storageKey": "avatar(height:40,width:40)",
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "height",
                                        "value": 40
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "width",
                                        "value": 40
                                      }
                                    ],
                                    "concreteType": "File",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "url",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "firstName",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "reputation",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              },
                              (v3/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "voting",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Voting",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "countUps",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "countDowns",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "sumValues",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "mine",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Vote",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "value",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "commenting",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Commenting",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "count",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "comments",
                "args": (v4/*: any*/),
                "handle": "connection",
                "key": "CommentsList_comments",
                "filters": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CommentsListQuery",
    "id": null,
    "text": "query CommentsListQuery(\n  $count: Int!\n  $cursor: String\n  $nodeID: ID!\n) {\n  node(id: $nodeID) {\n    __typename\n    ...CommentsList_commenting_1G22uz\n    id\n  }\n}\n\nfragment CommentsList_commenting_1G22uz on Commenting {\n  id\n  comments(first: $count, after: $cursor) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...CommentsItem_comment\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment CommentsItem_comment on Comment {\n  id\n  myPerms\n  body\n  document {\n    revisionsCount\n    id\n  }\n  revisionCreated {\n    createdAt\n    author {\n      id\n      username\n      avatar(width: 40, height: 40) {\n        url\n      }\n      ...ProfileLink_user\n    }\n    id\n  }\n  voting {\n    ...VotingButtons_voting\n    id\n  }\n  commenting {\n    id\n    count\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n\nfragment VotingButtons_voting on Voting {\n  id\n  countUps\n  countDowns\n  sumValues\n  mine {\n    id\n    value\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e47815b800c20db5342b4643e37a709c';
module.exports = node;
