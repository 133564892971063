/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProfileLink_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RevisionBox_document$ref: FragmentReference;
declare export opaque type RevisionBox_document$fragmentType: RevisionBox_document$ref;
export type RevisionBox_document = {|
  +id: string,
  +revisionsCount: number,
  +revisionTip: ?{|
    +id: string,
    +createdAt: any,
    +author: ?{|
      +username: string,
      +$fragmentRefs: ProfileLink_user$ref,
    |},
  |},
  +revisionCreated: ?{|
    +createdAt: any,
    +author: ?{|
      +username: string,
      +$fragmentRefs: ProfileLink_user$ref,
    |},
  |},
  +$refType: RevisionBox_document$ref,
|};
export type RevisionBox_document$data = RevisionBox_document;
export type RevisionBox_document$key = {
  +$data?: RevisionBox_document$data,
  +$fragmentRefs: RevisionBox_document$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProfileLink_user",
      "args": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "RevisionBox_document",
  "type": "Document",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "revisionsCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "revisionTip",
      "storageKey": null,
      "args": null,
      "concreteType": "Revision",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "revisionCreated",
      "storageKey": null,
      "args": null,
      "concreteType": "Revision",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '48d9542a781792e79e085dd106a712b1';
module.exports = node;
