/**
 * @flow
 * @relayHash 19ffef65c8a5882676ed9335f237817a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SuggestionItem_suggestionID$ref = any;
export type SuggestionIDCreateInput = {|
  occurrence: string,
  identity: string,
  notes?: ?string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type SuggestionAddMutationVariables = {|
  input: SuggestionIDCreateInput
|};
export type SuggestionAddMutationResponse = {|
  +suggestionIDCreate: ?{|
    +suggestionID: ?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: SuggestionItem_suggestionID$ref,
      |}
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type SuggestionAddMutation = {|
  variables: SuggestionAddMutationVariables,
  response: SuggestionAddMutationResponse,
|};
*/


/*
mutation SuggestionAddMutation(
  $input: SuggestionIDCreateInput!
) {
  suggestionIDCreate(input: $input) {
    suggestionID {
      node {
        id
        ...SuggestionItem_suggestionID
      }
    }
    errors {
      code
      location
      message
    }
  }
}

fragment SuggestionItem_suggestionID on SuggestionID {
  id
  myPerms
  notes
  author {
    avatar(width: 40, height: 40) {
      url
    }
    username
    ...ProfileLink_user
    id
  }
  identity {
    id
    rankDisplay
    description
    ...PlantLink_plant
  }
  voting {
    ...VotingButtons_voting
    id
  }
  revisionCreated {
    createdAt
    id
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}

fragment PlantLink_plant on LifeNode {
  id
  idInt
  title
  slug
}

fragment VotingButtons_voting on Voting {
  id
  countUps
  countDowns
  sumValues
  mine {
    id
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SuggestionIDCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SuggestionAddMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "suggestionIDCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SuggestionIDCreatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "suggestionID",
            "storageKey": null,
            "args": null,
            "concreteType": "SuggestionIDEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SuggestionID",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "SuggestionItem_suggestionID",
                    "args": null
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SuggestionAddMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "suggestionIDCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SuggestionIDCreatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "suggestionID",
            "storageKey": null,
            "args": null,
            "concreteType": "SuggestionIDEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SuggestionID",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "myPerms",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "avatar",
                        "storageKey": "avatar(height:40,width:40)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "height",
                            "value": 40
                          },
                          {
                            "kind": "Literal",
                            "name": "width",
                            "value": 40
                          }
                        ],
                        "concreteType": "File",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "url",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reputation",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "identity",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LifeNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rankDisplay",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "idInt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "voting",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Voting",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "countUps",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "countDowns",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "sumValues",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "mine",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Vote",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "value",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "revisionCreated",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Revision",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "SuggestionAddMutation",
    "id": null,
    "text": "mutation SuggestionAddMutation(\n  $input: SuggestionIDCreateInput!\n) {\n  suggestionIDCreate(input: $input) {\n    suggestionID {\n      node {\n        id\n        ...SuggestionItem_suggestionID\n      }\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n\nfragment SuggestionItem_suggestionID on SuggestionID {\n  id\n  myPerms\n  notes\n  author {\n    avatar(width: 40, height: 40) {\n      url\n    }\n    username\n    ...ProfileLink_user\n    id\n  }\n  identity {\n    id\n    rankDisplay\n    description\n    ...PlantLink_plant\n  }\n  voting {\n    ...VotingButtons_voting\n    id\n  }\n  revisionCreated {\n    createdAt\n    id\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n\nfragment PlantLink_plant on LifeNode {\n  id\n  idInt\n  title\n  slug\n}\n\nfragment VotingButtons_voting on Voting {\n  id\n  countUps\n  countDowns\n  sumValues\n  mine {\n    id\n    value\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a4c7ef541df2bdbf1ea17f584a9d7b10';
module.exports = node;
