/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VotingButtons_voting$ref: FragmentReference;
declare export opaque type VotingButtons_voting$fragmentType: VotingButtons_voting$ref;
export type VotingButtons_voting = {|
  +id: string,
  +countUps: ?number,
  +countDowns: ?number,
  +sumValues: ?number,
  +mine: ?{|
    +id: string,
    +value: number,
  |},
  +$refType: VotingButtons_voting$ref,
|};
export type VotingButtons_voting$data = VotingButtons_voting;
export type VotingButtons_voting$key = {
  +$data?: VotingButtons_voting$data,
  +$fragmentRefs: VotingButtons_voting$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "VotingButtons_voting",
  "type": "Voting",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countUps",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countDowns",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sumValues",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mine",
      "storageKey": null,
      "args": null,
      "concreteType": "Vote",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '065a0552b17e07039b6b39e909b6c7fe';
module.exports = node;
