/**
 * @flow
 * @relayHash 661d0947ec4475f6c9ed10462bc62dd4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProfileLink_user$ref = any;
export type PageQueryVariables = {|
  url: string
|};
export type PageQueryResponse = {|
  +page: ?{|
    +id: string,
    +url: string,
    +title: string,
    +body: string,
    +publishedAt: ?any,
    +myPerms: ?$ReadOnlyArray<?string>,
    +document: ?{|
      +revisionsCount: number
    |},
    +revisionCreated: ?{|
      +author: ?{|
        +$fragmentRefs: ProfileLink_user$ref
      |}
    |},
  |}
|};
export type PageQuery = {|
  variables: PageQueryVariables,
  response: PageQueryResponse,
|};
*/


/*
query PageQuery(
  $url: String!
) {
  page: pageByUrl(url: $url) {
    id
    url
    title
    body
    publishedAt
    myPerms
    document {
      revisionsCount
      id
    }
    revisionCreated {
      author {
        ...ProfileLink_user
        id
      }
      id
    }
  }
}

fragment ProfileLink_user on User {
  id
  username
  firstName
  reputation
  avatar(width: 40, height: 40) {
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "url",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "url",
    "variableName": "url"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "myPerms",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionsCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "page",
        "name": "pageByUrl",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Page",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "Document",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "revisionCreated",
            "storageKey": null,
            "args": null,
            "concreteType": "Revision",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "ProfileLink_user",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "page",
        "name": "pageByUrl",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Page",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "Document",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "revisionCreated",
            "storageKey": null,
            "args": null,
            "concreteType": "Revision",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "username",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reputation",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "avatar",
                    "storageKey": "avatar(height:40,width:40)",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "height",
                        "value": 40
                      },
                      {
                        "kind": "Literal",
                        "name": "width",
                        "value": 40
                      }
                    ],
                    "concreteType": "File",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PageQuery",
    "id": null,
    "text": "query PageQuery(\n  $url: String!\n) {\n  page: pageByUrl(url: $url) {\n    id\n    url\n    title\n    body\n    publishedAt\n    myPerms\n    document {\n      revisionsCount\n      id\n    }\n    revisionCreated {\n      author {\n        ...ProfileLink_user\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment ProfileLink_user on User {\n  id\n  username\n  firstName\n  reputation\n  avatar(width: 40, height: 40) {\n    url\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1ffe12534058a9c49e5bd8fd7e94227';
module.exports = node;
