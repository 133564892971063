/**
 * @flow
 * @relayHash 70a6dbf06ca86265b19eea2a805a7af7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Navbar_me$ref = any;
export type AuthenticateInput = {|
  username: string,
  password: string,
  clientMutationId?: ?string,
  revisionMessage?: ?string,
|};
export type AuthMutationVariables = {|
  input: AuthenticateInput
|};
export type AuthMutationResponse = {|
  +authenticate: ?{|
    +viewer: ?{|
      +id: string,
      +me: ?{|
        +id: string,
        +$fragmentRefs: Navbar_me$ref,
      |},
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?string,
      +location: ?string,
      +message: string,
    |}>,
  |}
|};
export type AuthMutation = {|
  variables: AuthMutationVariables,
  response: AuthMutationResponse,
|};
*/


/*
mutation AuthMutation(
  $input: AuthenticateInput!
) {
  authenticate(input: $input) {
    viewer {
      id
      me {
        id
        ...Navbar_me
      }
    }
    errors {
      code
      location
      message
    }
  }
}

fragment Navbar_me on User {
  id
  username
  avatar(width: 40, height: 40) {
    url
  }
  isAuthenticated
  myPerms
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AuthenticateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AuthMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authenticate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AuthenticatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Query",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "me",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "Navbar_me",
                    "args": null
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AuthMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authenticate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AuthenticatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Query",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "me",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "username",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "avatar",
                    "storageKey": "avatar(height:40,width:40)",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "height",
                        "value": 40
                      },
                      {
                        "kind": "Literal",
                        "name": "width",
                        "value": 40
                      }
                    ],
                    "concreteType": "File",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "url",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isAuthenticated",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "myPerms",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AuthMutation",
    "id": null,
    "text": "mutation AuthMutation(\n  $input: AuthenticateInput!\n) {\n  authenticate(input: $input) {\n    viewer {\n      id\n      me {\n        id\n        ...Navbar_me\n      }\n    }\n    errors {\n      code\n      location\n      message\n    }\n  }\n}\n\nfragment Navbar_me on User {\n  id\n  username\n  avatar(width: 40, height: 40) {\n    url\n  }\n  isAuthenticated\n  myPerms\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7054846cf546f298914798855f4c4ca0';
module.exports = node;
