/**
 * @flow
 * @relayHash 6d4457148e4bef66b741edfb5ef9bde8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ImageThumbnail_image$ref = any;
export type ImageQueryVariables = {|
  id: string,
  width: number,
  height: number,
|};
export type ImageQueryResponse = {|
  +image: ?{|
    +id: string,
    +description: ?string,
    +smallImage: ?{|
      +url: string
    |},
    +$fragmentRefs: ImageThumbnail_image$ref,
  |}
|};
export type ImageQuery = {|
  variables: ImageQueryVariables,
  response: ImageQueryResponse,
|};
*/


/*
query ImageQuery(
  $id: ID!
  $width: Int!
  $height: Int!
) {
  image(id: $id) {
    id
    description
    smallImage: image(width: $width, height: $height) {
      url
    }
    ...ImageThumbnail_image
  }
}

fragment ImageThumbnail_image on Image {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "width",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "height",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": "smallImage",
  "name": "image",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "height",
      "variableName": "height"
    },
    {
      "kind": "Variable",
      "name": "width",
      "variableName": "width"
    }
  ],
  "concreteType": "File",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ImageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "image",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Image",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "ImageThumbnail_image",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ImageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "image",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Image",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ImageQuery",
    "id": null,
    "text": "query ImageQuery(\n  $id: ID!\n  $width: Int!\n  $height: Int!\n) {\n  image(id: $id) {\n    id\n    description\n    smallImage: image(width: $width, height: $height) {\n      url\n    }\n    ...ImageThumbnail_image\n  }\n}\n\nfragment ImageThumbnail_image on Image {\n  id\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1126004938c29a27c3d01c87406342a';
module.exports = node;
